import React from 'react'
import { DefaultInputFieldProps } from '~/hooks/use-input'

import { TextField, Typography } from '@mui/material'

export type CommentFieldsProps = {
  wrongAnswer: DefaultInputFieldProps
  successAnswer: DefaultInputFieldProps
}

export const CommentFields = ({ wrongAnswer, successAnswer }: CommentFieldsProps) => {
  return (
    <>
      <Typography style={{ fontSize: 14 }}>Текстовый блок, который отображается после ответа пользователя</Typography>
      <TextField
        fullWidth
        label="Текст после верного ответа"
        value={successAnswer?.value}
        onChange={e => successAnswer?.handleSetValue?.(e.target.value)}
        variant="outlined"
        multiline
        style={{ marginTop: 10 }}
      />
      <TextField
        fullWidth
        label="Текст после не верного ответа"
        value={wrongAnswer?.value}
        onChange={e => wrongAnswer?.handleSetValue?.(e.target.value)}
        variant="outlined"
        multiline
        style={{ marginTop: 10 }}
      />
    </>
  )
}
