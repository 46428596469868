import React, { useCallback } from 'react'
import Loader from 'react-loader-spinner'
import { CourseDropletsApi } from '~/api/firebase/droplets'
import { SpecializationApi } from '~/api/firebase/specializations'
import { TaskApi } from '~/api/firebase/task'
import { UsersMetaApi } from '~/api/firebase/usersMeta'
import { SkillApi } from '~/api/skills'
import { FIREBASE_PROJECT_ID_PRODUCTION } from '~/config/env'
import { Course } from '~/domain/course'
import { Skill } from '~/domain/skills'
import { Specialization } from '~/domain/specializations'
import { Task } from '~/domain/task'
import { UserRole } from '~/domain/user'
import { useCourseQuery } from '~/hooks/use-course-query'
import { isSecondFirebaseReady } from '~/infra/firebase/firebase'

import { Button } from '@mui/material'

import { CourseApi } from '../../api/firebase/course'
import { MouseEventType } from '../../types/common'
import { Course as CourseType } from '../../types/course'
import { PrivateComponent } from '../private-component'
import { getTaskIds } from './helpers/get-task-ids'
import { restructureGoals } from './helpers/restructure-goals'

type Props = {
  course: CourseType
}

export const SyncToProductionFirestoreBtn = (props: Props) => {
  const { course } = props
  const [isLoading, setLoading] = React.useState<boolean>(false)

  const { fetchedCourse, refetch } = useCourseQuery({
    courseId: course.id,
    method: CourseApi.getSingleCourseFromProductionDB,
    key: FIREBASE_PROJECT_ID_PRODUCTION,
  })
  const syncBtnTitle = !!fetchedCourse ? 'Update to production Firestore' : 'Migrate to production Firestore'

  /**
   * Сбор курса и сохранение данных в pruduction database
   * 1 - Сохранить актуальную версию на текущий бд (стейдж)
   * 2 - вытащить идентификаторы, собрать объекты дополнительных данных
   * (без конфига частичной оплаты - слишком хрупкие данные, лучше точечно настраивать их в проде)
   *    2.1 - Специализации
   *    2.2 - Скиллы
   *    2.3 - Мета юзеры
   *    2.4 - IDE таски
   *    2.5 - Конфиги дроплетов
   */
  const handleCreateOrUpdateInDb = useCallback(
    async (e: MouseEventType, course: CourseType) => {
      e.preventDefault()
      if (
        window.confirm(
          'При переносе данных, рассрочку, если она была указана, нужно установить снова в продовой версии админки.\nЕсли вы продолжите, то данные на продакшене полностью перезапишутся, вы уверены?',
        )
      ) {
        setLoading(true)
        try {
          const parsedCourse = restructureGoals(course)
          const { course: courseWithoutLessons, lessons } = Course.separateCourseAndLessons(parsedCourse)
          await CourseApi.createOrUpdateCourseInProduction(courseWithoutLessons, lessons)

          console.info('Successfully update course and lessons...')
          await refetch()
        } catch (error) {
          console.error(error)
        }

        try {
          const { specializations, skillset } = course
          const taskIds = getTaskIds(course)
          const metaUsers = await UsersMetaApi.getUsersMeta(course?.id)
          const courseDroplets = await CourseDropletsApi.getDroplets(course?.id)

          console.info('Entities to update: ', { courseDroplets, metaUsers, skillset, specializations, taskIds })

          try {
            if (taskIds && taskIds?.length > 0) {
              for await (const item of taskIds) {
                const taskDocument = await TaskApi.getTask(item)
                const task = Task.fromRaw(taskDocument as any)
                await TaskApi.createOrUpdateTaskInProductionDB(Task.toRaw(task))
              }

              console.info('Successfully fetch and update tasks...')
            }
          } catch (error) {
            console.info(`Something wrong with fetch or update tasks: ${(error as any)?.message}`)
          }

          try {
            if (specializations && specializations?.length > 0) {
              for await (const item of specializations) {
                const specialization = Specialization.fromRaw(await SpecializationApi.fetchSpecialization(item))
                await SpecializationApi.createOrUpdateSpecializationInProductionDB(specialization)
              }

              console.info('Successfully fetch and update specializations...')
            }
          } catch (error) {
            console.info(`Something wrong with fetch or update specializations: ${(error as any)?.message}`)
          }

          try {
            if (skillset && skillset?.length > 0) {
              const skillTree = await SkillApi.getSkillTree(skillset)
              const skills = [...(skillTree?.area || []), ...(skillTree?.group || []), ...(skillTree.skills || [])]
              for await (const skill of skills) {
                await SkillApi.createOrUpdateSkillInProductionDB(Skill.fromRaw(skill))
              }

              console.info('Successfully fetch and update skills...')
            }
          } catch (error) {
            console.info(`Something wrong with fetch or update skills: ${(error as any)?.message}`)
          }

          try {
            if (metaUsers?.length > 0) {
              for await (const meta of metaUsers) {
                await UsersMetaApi.createOrUpdateUserMetaInProductionDB(meta)
              }

              console.info('Successfully update meta users...')
            }
          } catch (error) {
            console.info(`Something wrong with update meta users: ${(error as any)?.message}`)
          }

          try {
            if (courseDroplets?.length > 0) {
              for await (const droplet of courseDroplets) {
                await CourseDropletsApi.createOrUpdateDropletInProductionDB(droplet)
              }

              console.info('Successfully update course droplets...')
            }
          } catch (error) {
            console.info(`Something wrong with course droplets updating: ${(error as any)?.message}`)
          }
        } catch (error) {
          console.info(`Something wrong with fetch course entities ${(error as any)?.message}`)
        }

        // need sync all datas to production firestore
        setLoading(false)
      }
    },
    [refetch],
  )

  if (!isSecondFirebaseReady()) return null

  return (
    <PrivateComponent roles={[UserRole.editor]}>
      <Button
        variant="outlined"
        size="small"
        disabled={isLoading}
        onClick={e => handleCreateOrUpdateInDb(e, course)}
        color={!!fetchedCourse ? 'success' : 'info'}
      >
        {!isLoading ? syncBtnTitle : <Loader type="Oval" color="blue" height={12} width={12} />}
      </Button>
    </PrivateComponent>
  )
}
