import { Difficulty } from '~/domain/types'
import { CourseUsersMetaType } from '~/domain/usersMeta'
import { CommonObjectType } from '~/helpers/mdParser/types'
import { Author } from '~/modals/author-modal'

export enum MESSAGES_TYPES {
  DIALOG = 'DIALOG',
  MARKDOWN = 'MARKDOWN',
  MESSAGES_GROUP = 'MESSAGES_GROUP',
  FACT = 'FACT',
  FINISH = 'FINISH',
  DISCUSSION = 'DISCUSSION',
  SUMMARY = 'SUMMARY',
  MATCHING = 'MATCHING',
  QUIZ = 'QUIZ',
  BLANK = 'BLANK',
  CARD_INPUT = 'CARD_INPUT',
  DRAG_AND_DROP = 'DRAG_AND_DROP',
  THOUGHT = 'THOUGHT',
  QUOTE = 'QUOTE',
  GALLERY = 'GALLERY',
  ASSESMENT = 'ASSESMENT',
  ASSESMENT_RESULT = 'ASSESMENT_RESULT',
  TASK = 'TASK',
  GOAL_EVENT = 'GOAL_EVENT',
  GOALS_SLIDER = 'GOALS_SLIDER',
  FILL_THE_GAP = 'FILL_THE_GAP',
  START_DAY = 'START_DAY',
  GITHUB_TASK = 'GITHUB_TASK',
  GITHUB_LINK = 'GITHUB_LINK',
  CONNECT_WALLET = 'CONNECT_WALLET',
  DEPLOY_CONTRACT = 'DEPLOY_CONTRACT',
}

export const MessagesWithAuthor = [
  MESSAGES_TYPES.DIALOG,
  MESSAGES_TYPES.QUOTE,
  MESSAGES_TYPES.MATCHING,
  MESSAGES_TYPES.QUIZ,
  MESSAGES_TYPES.BLANK, // deprecated
  MESSAGES_TYPES.CARD_INPUT,
  MESSAGES_TYPES.DRAG_AND_DROP,
  MESSAGES_TYPES.GALLERY,
  MESSAGES_TYPES.TASK,
]

export type DifficultyType = {
  difficulty?: Difficulty
}

export const InteractiveTypes = [
  MESSAGES_TYPES.BLANK, // deprecated
  MESSAGES_TYPES.CARD_INPUT,
  MESSAGES_TYPES.DRAG_AND_DROP,
  MESSAGES_TYPES.MATCHING,
  MESSAGES_TYPES.QUIZ,
  MESSAGES_TYPES.GITHUB_TASK,
  MESSAGES_TYPES.GITHUB_LINK,
  MESSAGES_TYPES.DEPLOY_CONTRACT,
]

export enum ACCESS_TYPES {
  SHAREWARE = 'SHAREWARE',
  FREE = 'FREE',
  PAID = 'PAID',
}

export enum IDE_TYPES {
  LITTLE = 'LITTLE',
  LARGE = 'LARGE',
}

export type AuthorIdType = {
  authorId?: '0' | string
}

export type PriceType = {
  amountCents: number
  currencyCode: 'RUB' | 'USD'
}

export interface Course {
  id: string
  slug: string
  price?: PriceType | null
  discountPrice?: PriceType | null
  partialPaymentConfigId?: string | null
  imageUrl?: string | null
  simulatorVersion: number
  version: string
  name: string
  description?: string
  author?: Author | null
  isReady: boolean
  chapters?: Chapter[] | null
  specializations?: string[] | null
  difficulty?: string | null
  sponsors?: string[] | null
  courseUsersMeta?: CourseUsersMetaType[] | null
  skillset?: string[] | null
  accessType: ACCESS_TYPES
}

export interface Chapter {
  id: string
  name: string
  shortName?: string
  description?: string
  chapterVersion: number
  lessons?: LessonType[]
  messages?: IMessageGroup[]
  imageUrl?: string | null
}

export type LessonType = {
  id: string
  name: string
  // type: LESSON_TYPES
  // messages: MessageGroupType<MessageType[]>[]
  messages?: IMessageGroup[]
  goals?: LessonGoalType[]
  isOpenedAccess?: boolean
  description?: string
  imageUrl?: string | null
  type: 'CONTENT'
  duration?: number
}

export interface IMessageGroup {
  id: string
  type: string
  messages: IMessage[]
  closeGoals?: string[]
}

type BasicMessage = {
  id: string
  content: string
  // FIXME: move to MessageText once form parts separated
  author?: 'ME'
  authorMeta?: Author
  skills?: string[]
  authorId?: string
  nextButtonText?: string
}

export type DefaultMessageType = {
  id: string
  type: MESSAGES_TYPES.MARKDOWN
  content: string
  nextButtonText?: string
  hasNextButton?: boolean
  skills?: string[]
  authorId?: string
}

export type DialogMessageType = Omit<DefaultMessageType, 'type'> & {
  type: MESSAGES_TYPES.DIALOG
  authorId?: '0' | string
}

export type FactType = Omit<DefaultMessageType, 'type'> & {
  type: MESSAGES_TYPES.FACT
}

export type ThoughtsType = Omit<DefaultMessageType, 'type'> & {
  type: MESSAGES_TYPES.THOUGHT
}

export type QuoteType = Omit<DefaultMessageType, 'type'> & {
  type: MESSAGES_TYPES.QUOTE
  authorId?: string
  avatarSrc?: string
  authorText?: string
}

export type MediaSlideOptionType = {
  elementId: number | string
  source: string
  description?: string
  shortDescription?: string
  contentType: string
}

export type GalleryType = Omit<DefaultMessageType, 'type' | 'content'> & {
  type: MESSAGES_TYPES.GALLERY
  slides: MediaSlideOptionType[]
}

export type MatchingOptionType = {
  id: number
  content: string
  position: number
}

export type MatchingType = Omit<DefaultMessageType, 'type'> &
  Comments &
  DifficultyType & {
    type: MESSAGES_TYPES.MATCHING
    title: string
    options: MatchingOptionType[]
    value?: any
  }

export type FillTheGapType = Omit<DefaultMessageType, 'type'> &
  Comments &
  DifficultyType & {
    type: MESSAGES_TYPES.FILL_THE_GAP
    title?: string | undefined
    value?: any
    options?: CommonObjectType
    description?: string | undefined
  }

export type QuizOption = {
  id: string
  content: string
  hint: string
  notChoosenAndCorrectHint?: string
  imgUrl?: string
  isCorrect: boolean
}

export type QuizType = Omit<DefaultMessageType, 'type'> &
  Comments &
  DifficultyType & {
    type: MESSAGES_TYPES.QUIZ
    title: string
    optionType: 'radio' | 'checkbox'
    options: QuizOption[]
    value?: any
  }

export type BlankOption = {
  id: string
  content: string
  position?: number
}

/**
 * @deprecated
 **/
export type BlankType = Omit<DefaultMessageType, 'type'> &
  Comments &
  DifficultyType & {
    type: MESSAGES_TYPES.BLANK
    title: string
    sourceCode: string
    options: BlankOption[]
    value?: any
  }

export type SingleCard = {
  id: string
  content: string
}

export type SingleColumn = {
  id: string
  title: string
  cardIds: string[] | []
  correctCardIds: string[]
}

export type DragAndDropOption = {
  cards: {
    [cardId: string]: SingleCard
  }
  columns: {
    [columnId: string]: SingleColumn
  }
  columnOrder: string[]
}

export type DragAndDrop = Omit<DefaultMessageType, 'type'> &
  Comments &
  DifficultyType & {
    type: MESSAGES_TYPES.DRAG_AND_DROP
    title: string
    options: DragAndDropOption
  }

export type SummaryMessageType = Omit<DefaultMessageType, 'type'> & {
  type: MESSAGES_TYPES.SUMMARY
}

export type FinishMessageType = Omit<DefaultMessageType, 'type'> & {
  type: MESSAGES_TYPES.FINISH
}
export type GoalEventMessageType = Omit<DefaultMessageType, 'type'> & {
  type: MESSAGES_TYPES.GOAL_EVENT
  targetGoalId: string
}

export type GoalsSliderType = Omit<DefaultMessageType, 'type'> & {
  type: MESSAGES_TYPES.GOALS_SLIDER
}

export type InteractiveMessageType = QuizType | MatchingType | BlankType | DragAndDrop | TaskType | GithubTaskType

export type AssesmentsMessageType = DefaultMessageType | InteractiveMessageType

export type AssesmentMessageType = Omit<DefaultMessageType, 'type'> & {
  type: MESSAGES_TYPES.ASSESMENT
  messages: AssesmentsMessageType[]
  title?: string
  totalSeconds: number
}

export type AssesmentResultMessageType = Omit<DefaultMessageType, 'type'> & {
  type: MESSAGES_TYPES.ASSESMENT_RESULT
  assesmentId: string
}

export type StartDayMessageType = Omit<DefaultMessageType, 'type'> & {
  type: MESSAGES_TYPES.START_DAY
  header?: string
  smallHeader?: string
}

export type GithubLinkMessageType = Omit<DefaultMessageType, 'type'> &
  DifficultyType & {
    type: MESSAGES_TYPES.GITHUB_LINK
    header?: string
    awardedText?: string
  }
export type DeployContractMessageType = Omit<DefaultMessageType, 'type'> &
  DifficultyType & {
    type: MESSAGES_TYPES.DEPLOY_CONTRACT
    contractCode: string
    initialBalance: number
    provider: string
    abi: any
    bytecode: string
  }
export type ConnectWalletType = Omit<DefaultMessageType, 'type'> &
  AuthorIdType &
  Comments &
  DifficultyType & {
    type: MESSAGES_TYPES.CONNECT_WALLET
    title: string
    isInteractiveBlockPromotion?: boolean
  }

export type TaskType = Omit<DefaultMessageType, 'type'> &
  AuthorIdType & {
    type: MESSAGES_TYPES.TASK
    ideType: IDE_TYPES
    taskId: string
    progressFromTaskId?: string
    points?: number
    additionalCheckMessage?: string
    additionalCheckCode?: string
  }

export type GithubTaskType = Omit<DefaultMessageType, 'type'> &
  AuthorIdType &
  Comments &
  DifficultyType & {
    type: MESSAGES_TYPES.GITHUB_TASK
    githubReference: string
    actionNames: string[] | null
    taskId: string
  }

export type IMessage =
  | GithubTaskType
  | MessageMarkdown
  | MessageTextInput
  | DialogMessageType
  | FactType
  | ThoughtsType
  | QuoteType
  | GalleryType
  | MatchingType
  | QuizType
  | BlankType
  | DragAndDrop
  | SummaryMessageType
  | FinishMessageType
  | GoalEventMessageType
  | TaskType
  | GoalsSliderType
  | FillTheGapType
  | AssesmentMessageType
  | AssesmentResultMessageType
  | StartDayMessageType
  | GithubLinkMessageType
  | ConnectWalletType
  | DeployContractMessageType

export type MessageType = IMessage['type']

export type OptionType = 'radio' | 'checkbox'

export type MessageMarkdown = BasicMessage & {
  type: MESSAGES_TYPES.MARKDOWN
}

export type VariableAnswer = {
  response: string | number
  comment: string
  isCorrect: boolean
}

export type Comments = {
  successAnswer?: string
  wrongAnswer?: string
}

export type MessageTextInput = BasicMessage &
  Comments &
  DifficultyType & {
    type: MESSAGES_TYPES.CARD_INPUT
    title: string
    //TODO: Add support VariableAnswer type
    answers: string[]
    hint: string
    variable?: string
    value?: any
  }

export type LessonGoalType = {
  id: string
  type: string
  text: string
  description?: string
  minutes?: string
  imageUrl?: string | null
  iconUrl?: string | null
}
