import { COURSE_COLLECTION_KEY } from '~/constants/firebase-collections-keys'
import { Course } from '~/domain/course'
import { Lesson } from '~/domain/lesson'
import { dbProduction } from '~/infra/firebase/firebase'
import { getQuerySnapshot, getCollectionRef } from '~/infra/firebase/firestore'
import { Chapter, LessonType } from '~/types/course'

import { Firestore } from '@firebase/firestore'

import { LessonApi } from './lesson'

export const getCourseCollection = (courseId?: string, db?: Firestore) =>
  getCollectionRef(COURSE_COLLECTION_KEY, {
    id: courseId,
    customDb: db,
    converter: {
      fromFirestore: (snapshot: any) => Course.fromRaw(snapshot.data()),
      toFirestore: (course: Course) => Course.toRaw(course),
    },
  })

const getSingleCourse = async (courseId?: string) => {
  const course = await getCourseCollection(courseId).get()

  return course.exists() ? Course.fromRaw(course.data()) : null
}

const getSingleCourseFromProductionDB = async (courseId?: string) => {
  const course = await getCourseCollection(courseId, dbProduction).get()

  return course.exists() ? Course.fromRaw(course.data()) : null
}

const isCourseInDb = async (courseId?: string) => {
  const course = await getCourseCollection(courseId).get()

  return course.exists()
}

const isCourseInProductionDb = async (courseId?: string) => {
  const course = await getCourseCollection(courseId, dbProduction).get()

  return course.exists()
}

const createOrUpdateCourse = async (data: Course) => {
  const { course, lessons } = Course.separateCourseAndLessons(data)
  const courseCollection = getCourseCollection(data.id)

  await courseCollection.set(Course.fromRaw(course))

  for await (let lesson of lessons) {
    const lessonCollection = LessonApi.getLessonCollection(lesson.id)
    await lessonCollection.set(lesson)
  }

  return { course, lessons }
}

const createOrUpdateCourseInProduction = async (course: Course | any, lessons: LessonType[]) => {
  const courseCollection = getCourseCollection(course.id, dbProduction)

  await courseCollection.set(Course.fromRaw(course))

  for await (let lesson of lessons) {
    const lessonCollection = LessonApi.getLessonCollection(lesson.id, dbProduction)
    await lessonCollection.set(lesson)
  }

  return { course, lessons }
}

// TODO: figure out will it be here or not
// const deleteCourse = async (courseId: string) => {
//   const courseCollection = getCourseCollection(courseId)
//   courseCollection.set(null)
// }

const getAllCoursesWithContent = async () => {
  const courseDocs = await getQuerySnapshot(COURSE_COLLECTION_KEY).get()

  const courses = courseDocs.docs.map(doc => Course.fromRaw({ ...doc.data(), id: doc.id }))

  // TODO: здесь узкое горлышко, тут собирается контент всех уроков с таблицы уроки по всем курсам.
  // TODO: Это надо убрать от сюда и сделать скачку контента курса по открытию курса
  let computedCourses: Course[] = []

  for await (const course of courses) {
    const { chapters = [] } = course
    let newChapters: Chapter[] = []

    for await (const chapter of chapters || []) {
      const { lessons = [] } = chapter
      let newLessons: LessonType[] = []

      for await (const lessonId of lessons) {
        if (typeof lessonId !== 'string') {
          newLessons = [...newLessons, lessonId]

          continue
        }

        const lessonDocument = await LessonApi.getLesson(lessonId as any)
        const lessonData = Lesson.toRaw(Lesson.fromRaw(lessonDocument))

        newLessons = [...newLessons, ...(lessonData ? [lessonData] : [])]
      }

      newChapters = [...newChapters, { ...chapter, lessons: newLessons }]
    }

    computedCourses = [
      ...computedCourses,
      {
        ...course,
        chapters: newChapters,
      },
    ]
  }

  return computedCourses
}

export const CourseApi = {
  createOrUpdateCourse,
  getAllCoursesWithContent,
  getSingleCourse,
  getSingleCourseFromProductionDB,
  isCourseInDb,
  // PRODUCTION MIGRATION
  isCourseInProductionDb,
  createOrUpdateCourseInProduction,
  // deleteCourse,
}
