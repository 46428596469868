import classNames from 'classnames'
import React, { FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Direction } from '~/domain/types'
import { insertAndShift } from '~/helpers/insert-and-shift'
import { LessonModal } from '~/modals/lesson-modal'
import { Chapter, Course, LessonType } from '~/types/course'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, List, ListItemAvatar, Menu, MenuItem } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(to right, rgba(161, 196, 253, 0.3), rgba(194, 233, 251, 0.3))',
  },
})

interface ChaptersListProps {
  selectedChapter: Chapter | undefined
  onSelectLesson: (lesson: string) => void
  onDeleteLesson: (id: string) => void
  onUpdateLesson: (lesson: LessonType) => void
  selectedLessonId?: string
  onUpdateChapter: (chapter: Chapter) => void
  course: Course
  onUpdateCourse: (course: Course) => void
}

export const LessonsList: FunctionComponent<ChaptersListProps> = React.memo(
  ({
    selectedChapter,
    onSelectLesson,
    selectedLessonId,
    onDeleteLesson,
    onUpdateLesson,
    onUpdateChapter,
    course,
    onUpdateCourse,
  }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [openedMenuLessonId, setOpenedMenuLessonId] = useState<string | null>(null)
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, lesson: LessonType) => {
      setAnchorEl(event.currentTarget)
      setOpenedMenuLessonId(lesson.id)
    }
    const handleCloseMenu = () => {
      setAnchorEl(null)
      setOpenedMenuLessonId(null)
    }
    const classes = useStyles()
    const lessons = selectedChapter?.lessons

    const [activeLesson, setActiveLesson] = useState<LessonType>()
    const [lessonModalIsVisible, setLessonModalIsVisible] = useState(false)

    const handleCloseLessonModal = useCallback(() => {
      setLessonModalIsVisible(false)
    }, [])

    const handleEditLessonButtonClick = useCallback((lesson: LessonType) => {
      setLessonModalIsVisible(true)
      setActiveLesson(lesson)
      handleCloseMenu()
    }, [])

    const handleMoveLesson = (lesson: LessonType, direction: Direction) => {
      if (selectedChapter?.lessons) {
        const { lessons } = selectedChapter

        const index = lessons.findIndex(item => item.id === lesson.id)
        const totalMessages = lessons.length - 1

        const isFirstMessage = direction === Direction.UP && index === 0
        const isLastMessage = direction === Direction.DOWN && index === totalMessages

        if (isFirstMessage || isLastMessage) return

        const indexOfPreviousElement = index - 1
        const indexOfNextElement = index + 1

        const resultLessonsList =
          direction === Direction.UP
            ? insertAndShift(lessons, index, indexOfPreviousElement)
            : insertAndShift(lessons, index, indexOfNextElement)
        const newChapterData = { ...selectedChapter, lessons: resultLessonsList }
        onUpdateChapter(newChapterData)
        handleCloseMenu()
      }
    }

    const handleMoveToAnotherChapter = (lesson: LessonType, direction: Direction) => {
      const { chapters } = course
      const chaptersTemp = chapters ? [...chapters!] : []
      const currentChapterIndex = chaptersTemp?.findIndex(item => item.id === selectedChapter?.id)
      if (currentChapterIndex !== undefined && currentChapterIndex !== -1) {
        const currentChapter = chaptersTemp[currentChapterIndex]
        let targetChapter = null
        if (direction === Direction.UP) {
          if (currentChapterIndex === 0) return // если первая глава нельзя вверх
          targetChapter = chaptersTemp[currentChapterIndex - 1]
        }
        if (direction === Direction.DOWN) {
          if (currentChapterIndex === chaptersTemp?.length - 1) return // если последняя глава нельзя вниз
          targetChapter = chaptersTemp[currentChapterIndex + 1]
        }
        if (targetChapter && selectedChapter) {
          targetChapter?.lessons?.push(lesson)
          currentChapter?.lessons?.splice(currentChapter?.lessons?.indexOf(lesson), 1)
          onUpdateCourse({ ...course, chapters: chaptersTemp })
        }
      }
      handleCloseMenu()
    }

    return (
      <>
        {lessonModalIsVisible && (
          <LessonModal
            onSave={onUpdateLesson}
            lesson={activeLesson}
            onClose={handleCloseLessonModal}
            isOpen={lessonModalIsVisible}
          />
        )}
        {lessons && (
          <List>
            {lessons.map(lesson => (
              <ListItem
                button
                component="a"
                href="#"
                key={lesson.id}
                onClick={() => onSelectLesson(lesson.id)}
                className={classNames({ [classes.root]: selectedLessonId === lesson.id })}
              >
                <ListItemAvatar>
                  <Image as="img" src={lesson?.imageUrl ?? ''} />
                </ListItemAvatar>
                <ListItemText primary={lesson.name} />
                <ListItemSecondaryAction>
                  <IconButton onClick={e => handleOpenMenu(e, lesson)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && openedMenuLessonId === lesson.id}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={() => handleEditLessonButtonClick(lesson)}>Редактировать</MenuItem>
                    <MenuItem
                      onClick={() => {
                        onDeleteLesson(lesson.id)
                        handleCloseMenu()
                      }}
                    >
                      Удалить урок
                    </MenuItem>
                    <MenuItem onClick={() => handleMoveLesson(lesson, Direction.UP)}>Переместить вверх</MenuItem>
                    <MenuItem onClick={() => handleMoveLesson(lesson, Direction.DOWN)}>Переместить вниз</MenuItem>
                    <MenuItem onClick={() => handleMoveToAnotherChapter(lesson, Direction.UP)}>
                      Переместить в предыдущую главу
                    </MenuItem>
                    <MenuItem onClick={() => handleMoveToAnotherChapter(lesson, Direction.DOWN)}>
                      Переместить в следующую главу
                    </MenuItem>
                  </Menu>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </>
    )
  },
)

LessonsList.displayName = 'LessonsList'

const Image = styled(Box)`
  border-radius: 4px;
  overflow: hidden;
  width: 48px;
  height: 48px;
  object-fit: cover;
  margin-right: 8px;
`
