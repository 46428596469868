import React, { useContext, useEffect } from 'react'
import { CourseNotFound } from '~/components/course-not-found'
import { CourseNav } from '~/components/courseNav/course-nav'
import { courseNavButtons } from '~/components/courseNav/course-nav-buttons'
import { Header } from '~/components/header'
import { coursesContext } from '~/contexts/courses'
import { useCourseRoute } from '~/hooks/use-course-route'
import { DefaultLayout } from '~/layouts/default-layout'

import UsersAnalytics from './components/users-analytics'

export const CourseAnalyticsPage = () => {
  const { id, pathIdentifier } = useCourseRoute()
  const { getCourse, course } = useContext(coursesContext)

  useEffect(() => {
    getCourse(id)
  }, [id, getCourse])

  return course ? (
    <DefaultLayout
      backProps={{ text: '<- Return to courses', url: '/' }}
      navChapters={<CourseNav id={id} buttons={courseNavButtons} pathIdentifier={pathIdentifier} />}
      headerComponent={<Header title={`Аналитика курса "${course?.name}"`} />}
    >
      <UsersAnalytics />
    </DefaultLayout>
  ) : (
    <CourseNotFound />
  )
}
