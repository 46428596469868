import { CourseUsersMetaType } from '~/domain/usersMeta'
import { getUniqueId } from '~/helpers/id'
import { Author } from '~/modals/author-modal'
import { Course as ICourse, Chapter, ACCESS_TYPES, LessonType, PriceType } from '~/types/course'

export class Course {
  id: string
  slug: string
  price?: PriceType | null
  discountPrice?: PriceType | null
  partialPaymentConfigId?: string | null
  imageUrl?: string | null
  simulatorVersion: number
  version: string
  name: string
  description?: string
  author?: Author | null
  isReady: boolean
  chapters?: Chapter[] | null
  specializations?: string[] | null
  difficulty?: string | null
  sponsors?: string[] | null
  courseUsersMeta?: CourseUsersMetaType[] | null
  skillset?: string[] | null
  accessType: ACCESS_TYPES

  constructor(course: ICourse) {
    this.id = course.id ?? getUniqueId()
    this.slug = course.slug
    this.price = course?.price
    this.discountPrice = course?.discountPrice ?? null
    this.partialPaymentConfigId = course?.partialPaymentConfigId ?? null
    this.imageUrl = course?.imageUrl ?? null
    this.simulatorVersion = course.simulatorVersion
    this.version = course.version
    this.name = course.name ?? ''
    this.description = course.description ?? ''
    this.author = course?.author ?? null
    this.isReady = course?.isReady ?? false
    this.chapters = course?.chapters ?? null
    this.specializations = course?.specializations ?? null
    this.difficulty = course?.difficulty ?? null
    this.sponsors = course?.sponsors ?? null
    this.courseUsersMeta = course?.courseUsersMeta ?? null
    this.skillset = course?.skillset
    this.accessType = course?.accessType
  }

  static fromRaw(rawCourse: any) {
    return new Course(rawCourse)
  }

  static toRaw(course: Course) {
    return Object.assign({}, course)
  }

  static separateCourseAndLessons(course: Course) {
    const { chapters } = course

    let lessons: LessonType[] = []

    const newChapters = chapters?.map(chapter => {
      const lessonIds = chapter?.lessons?.map(lesson => lesson.id)
      lessons = [...lessons, ...(chapter?.lessons ?? [])]

      return {
        ...chapter,
        lessons: lessonIds,
      }
    })

    return {
      course: {
        ...course,
        chapters: newChapters,
      },
      lessons,
    }
  }
}
