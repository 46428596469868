import React, { useCallback, useEffect, useState } from 'react'
import { CustomButton } from '~/components/button'
import { MessageModal } from '~/modals/message-modal/message-modal'
import { AssesmentsMessageType, IMessage } from '~/types/course'

import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, IconButton, Paper, Typography } from '@mui/material'

type AssesmentProps = {
  message?: any
  questions: AssesmentsMessageType[]
  setQuestions: (value: AssesmentsMessageType[]) => void
}

export const Assesment = ({ message, questions, setQuestions }: AssesmentProps) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [editableMessage, setEditableMessage] = useState<IMessage | null>(null)
  useEffect(() => {
    if (message?.messages) {
      setQuestions(message.messages)
    }
  }, [message, setQuestions])

  const handleCloseModal = useCallback(() => {
    if (editableMessage) {
      setEditableMessage(null)
    } else {
      setIsAddModalOpen(false)
    }
  }, [editableMessage])

  const handleCreateMessage = (message: IMessage) => {
    const msgIndex = questions.findIndex(msg => msg.id === message.id)
    const messagesTemp: any[] = [...questions]
    if (msgIndex > -1) {
      messagesTemp[msgIndex] = message
    } else {
      messagesTemp.push(message)
    }
    setQuestions(messagesTemp)
    if (editableMessage) {
      setEditableMessage(null)
    }
  }

  const handleClickAddNewTask = () => {
    setIsAddModalOpen(true)
  }

  const handleEditMessage = (msg: AssesmentsMessageType) => {
    setEditableMessage(msg)
  }

  const handleDeleteMessage = (msg: IMessage) => {
    const removableIndexMsg = questions.findIndex((item: IMessage) => item.id === msg.id)
    const messagesTemp = [...questions]
    messagesTemp.splice(removableIndexMsg, 1)
    setQuestions(messagesTemp)
  }

  const handleArrowUp = (msg: IMessage) => {
    let messagesTemp = [...questions]
    const msgIndex = messagesTemp.findIndex(item => item.id === msg.id)
    const prevIndex = msgIndex - 1
    if (prevIndex !== -1) {
      const prevEl = messagesTemp[prevIndex]
      messagesTemp[msgIndex] = prevEl
      messagesTemp[prevIndex] = msg as unknown as AssesmentsMessageType

      setQuestions(messagesTemp)
    }
  }

  const handleArrowDown = (msg: IMessage) => {
    let messagesTemp = [...questions]
    const msgIndex = messagesTemp.findIndex(item => item.id === msg.id)
    const nextIndex = msgIndex + 1
    if (messagesTemp[nextIndex]) {
      const prevEl = messagesTemp[nextIndex]
      messagesTemp[msgIndex] = prevEl
      messagesTemp[nextIndex] = msg as unknown as AssesmentsMessageType

      setQuestions(messagesTemp)
    }
  }

  return (
    <Box>
      <Typography fontSize={12} color="#A6A6A6">
        Assesment Id: {message?.id}
      </Typography>
      {questions.map(msg => {
        return (
          <Box key={msg.id} mt={1}>
            <Paper>
              <Box p={1} justifyContent="space-between" alignItems="center" flexDirection="row" display="flex">
                <Typography fontSize={14} color="#A6A6A6">
                  type: {msg.type}
                </Typography>
                <div>
                  <CustomButton color="blue" variant="contained" type="button" onClick={() => handleEditMessage(msg)}>
                    Edit
                  </CustomButton>
                  <IconButton onClick={() => handleDeleteMessage(msg)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={() => handleArrowUp(msg)}>
                    <KeyboardArrowUpIcon />
                  </IconButton>
                  <IconButton onClick={() => handleArrowDown(msg)}>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </div>
              </Box>
            </Paper>
          </Box>
        )
      })}
      <Box mb={2} mt={2}>
        <CustomButton color="blue" variant="contained" type="button" onClick={handleClickAddNewTask}>
          <Box p={1}>Добавить задание</Box>
        </CustomButton>
      </Box>

      {(editableMessage || isAddModalOpen) && (
        <MessageModal
          message={editableMessage ?? undefined}
          isOpen={isAddModalOpen || !!editableMessage}
          onClose={handleCloseModal}
          onSave={handleCreateMessage}
        />
      )}
    </Box>
  )
}
