import { useCallback, useContext, useState } from 'react'
import { CourseApi } from '~/api/firebase/course'
import { FIREBASE_PROJECT_ID } from '~/config/env'
import { coursesContext } from '~/contexts/courses'
import { useCourseQuery } from '~/hooks/use-course-query'
import { getBumpedCourseVersion } from '~/hooks/use-course-version'
import { MouseEventType } from '~/types/common'
import { Course as CourseType } from '~/types/course'

import { restructureGoals } from '../helpers/restructure-goals'

type Props = {
  course: CourseType
}

export const useUpdateCourse = ({ course }: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const { fetchedCourse, refetch } = useCourseQuery({
    courseId: course.id,
    method: CourseApi.getSingleCourse,
    key: FIREBASE_PROJECT_ID,
  })
  const { handleUpdateCourse } = useContext(coursesContext)

  const handleCreateOrUpdateInDb = useCallback(
    async (e: MouseEventType, course: CourseType) => {
      e.preventDefault()

      try {
        const { data: dbCourse } = await refetch()

        if (!window.confirm('Этим действием вы полностью обновите курс на платформе, вы уверены?')) {
          return
        }

        if (dbCourse?.version && dbCourse?.version !== course?.version) {
          alert(
            `Операция прервана, так как ваша версия не соответствует версии на платформе:\nВаша ${course?.version}\nВ базе данных ${dbCourse?.version}\nЧтобы загрузить курс, удалите вашу локальную версию при помощи кнопки Удалить в списке курсев`,
          )
          return
        }

        const version = getBumpedCourseVersion(dbCourse?.version ?? '0.0.0')

        if (!version) {
          alert('Произошла ошибка при обновлении значения версии курса')

          return
        }

        setLoading(true)
        const parsedCourse = restructureGoals({ ...course, version })
        await CourseApi.createOrUpdateCourse(parsedCourse)
        await refetch()
        handleUpdateCourse({ ...course, version })

        return { ...course, version }
      } catch (ex) {
        console.info(`Something error ${(ex as any)?.message}`)
        alert(`Что то пошло не так: ${(ex as any)?.message}`)

        return undefined
      } finally {
        setLoading(false)
      }
    },
    [handleUpdateCourse, refetch],
  )

  return { isLoading, fetchedCourse, handleCreateOrUpdateInDb }
}
