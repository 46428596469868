import { db } from '~/infra/firebase/firebase'
import { getToken } from '~/utils/get-token'

import { doc, getDoc, setDoc, collection, getDocs } from '@firebase/firestore'

import { POST } from './rest'

export type AchievementSeriesType = {
  id?: string
  name: string
  users: string[]
}

type SeriesType = {
  name: string
  description?: string
}

export type AchievementType = {
  series: SeriesType
  title: string
  description: string
  image: string
  imageAlt: string
}

type GetAchievementsResponse = {
  achievementsSeries: AchievementSeriesType[]
}

const getAchievements = async (): Promise<GetAchievementsResponse> =>
  await POST('achievements-getAllAchievementsSeries', {}, getToken())

const createAchievement = async (name: string) =>
  await POST('achievements-createAchievementSeries', { name }, getToken())

const getAchievementSeries = async () => {
  const achievementSeriesRef = await collection(db, 'achievements-series')
  const achievementSeriesSnapshot = await getDocs(achievementSeriesRef)
  return achievementSeriesSnapshot.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  })) as AchievementSeriesType[]
}

const getAchievementById = async (id: string) => {
  const achievementRef = await doc(db, 'achievements', id)
  const achievementSnapshot = await getDoc(achievementRef)
  return achievementSnapshot.data() as AchievementType
}

const updateAchievementById = async (id: string, achievement: AchievementType) => {
  const achievementRef = await doc(db, 'achievements', id)
  await setDoc(achievementRef, achievement)
}

export const AchievementsApi = {
  getAchievements,
  createAchievement,
  getAchievementSeries,
  getAchievementById,
  updateAchievementById,
}
