import React, { useContext, useEffect } from 'react'
import { CourseNotFound } from '~/components/course-not-found'
import { CourseNav } from '~/components/courseNav/course-nav'
import { courseNavButtons } from '~/components/courseNav/course-nav-buttons'
import { Header } from '~/components/header'
import { coursesContext } from '~/contexts/courses'
import { useCourseRoute } from '~/hooks/use-course-route'
import { DefaultLayout } from '~/layouts/default-layout'
import { courseUsersMetaStore } from '~/store'

import { Container } from '@mui/material'

import { AddNewUserMeta } from './add-new-user-meta'
import { UsersMetaTable } from './course-users-meta-table'

export const CourseUsersMetaPage = () => {
  const { id, pathIdentifier } = useCourseRoute()
  const { getCourse, course } = useContext(coursesContext)

  useEffect(() => {
    getCourse(id)
  }, [id, getCourse])

  useEffect(() => {
    courseUsersMetaStore.fetchUsersMeta(id ?? '')
  }, [id])

  return course ? (
    <DefaultLayout
      backProps={{ text: '<- Return to courses', url: '/' }}
      navChapters={<CourseNav id={id} buttons={courseNavButtons} pathIdentifier={pathIdentifier} />}
      headerComponent={<Header title={`Мета юзеры курса "${course?.name}"`} />}
    >
      <Container maxWidth="lg">
        <AddNewUserMeta />
        <UsersMetaTable />
      </Container>
    </DefaultLayout>
  ) : (
    <CourseNotFound />
  )
}
