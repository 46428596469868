import { POST } from '~/api/rest'
import { getToken } from '~/utils/get-token'

export type CompileContractResponse = {
  abi: any
  bytecode: string
}

export const compileContract = async (contractSource: string): Promise<CompileContractResponse> => {
  const token = await getToken()
  const response = await POST<CompileContractResponse>('tasks-compileContract', {
    token,
    body: {
      contractSource,
    },
  })
  return response
}
