import React, { useEffect } from 'react'
import { Difficulty } from '~/domain/types'
import { IMessage, MESSAGES_TYPES } from '~/types/course'

import { Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material'

type DifficultySelectProps = {
  message?: IMessage
  difficulty?: keyof typeof Difficulty
  setDifficulty: (str: string | undefined) => void
}

export const DifficultySelect = ({ message, difficulty, setDifficulty }: DifficultySelectProps) => {
  useEffect(() => {
    if (!message) return
    if (
      message?.type === MESSAGES_TYPES.CARD_INPUT ||
      message?.type === MESSAGES_TYPES.BLANK || // deprecated
      message?.type === MESSAGES_TYPES.DRAG_AND_DROP ||
      message?.type === MESSAGES_TYPES.MATCHING ||
      message?.type === MESSAGES_TYPES.QUIZ
    ) {
      setDifficulty(message?.difficulty)
    }
  }, [message, setDifficulty])

  const handleDifficulty = (event: SelectChangeEvent) => {
    const value = event.target.value as keyof typeof Difficulty
    setDifficulty(value)
  }

  return (
    <Box my={2}>
      <FormControl fullWidth>
        <InputLabel id="difficulty-select-label">Level</InputLabel>
        <Select labelId="difficulty-select-label" value={difficulty} label="Level" onChange={handleDifficulty}>
          <MenuItem value={Difficulty.EASY}>Легкий</MenuItem>
          <MenuItem value={Difficulty.MEDIUM}>Средний</MenuItem>
          <MenuItem value={Difficulty.HARD}>Тяжелый</MenuItem>
          <MenuItem value={Difficulty.LEGENDARY}>Легендарный</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}
