import { getUniqueId } from '~/helpers/id'

import { Difficulty } from './types'

export type Structure = {
  name: string
  content: string
}

export enum Language {
  JAVASCRIPT = 'javascript',
  SOLIDITY = 'solidity',
}

export const languages = Object.values(Language).map(value => ({ label: value, value }))

export type TaskType = {
  id: string
  title: string
  description: string
  difficulty: Difficulty
  hint?: string
  language?: string | null
  codeExampleAnswer?: Structure[] | null
  structure?: Structure[]
  createdAt?: number
  updatedAt?: number
}

export class Task {
  id: string
  title: string
  description: string
  difficulty: Difficulty
  hint?: string
  language?: string | null
  codeExampleAnswer?: Structure[] | null
  structure?: Structure[]
  createdAt?: number
  updatedAt?: number

  constructor(task: TaskType) {
    const updatedAt = Date.now()

    this.id = task.id ?? getUniqueId()
    this.title = task.title
    this.description = task.description
    this.difficulty = task.difficulty
    this.hint = task.hint ?? ''
    this.structure = task.structure
    this.language = task?.language ?? null
    this.codeExampleAnswer = task?.codeExampleAnswer ?? null
    this.createdAt = task.createdAt ?? updatedAt
    this.updatedAt = updatedAt
  }

  static toRaw(item: TaskType) {
    return Object.assign({}, item)
  }

  static fromRaw(item: Task): TaskType {
    return new Task(item)
  }
}
