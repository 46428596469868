import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { ButtonsGroup } from '~/components/buttons-group'
import { getUniqueId } from '~/helpers/id'
import { containValueValidator } from '~/helpers/validation'
import { useInput } from '~/hooks/use-input'
import { LessonGoalType } from '~/types/course'

import { Box, Dialog, TextField } from '@mui/material'

interface Props {
  onSave: (goal: LessonGoalType) => void
  onClose: () => void
  goal?: LessonGoalType | null
  isOpen: boolean
}

export const GoalsModal = React.memo(({ onClose, isOpen, goal, onSave }: Props) => {
  const id = goal?.id ?? getUniqueId()
  const isEdit = Boolean(goal)

  const {
    value: text,
    handleSetValue: handleSetText,
    isValid: textIsValid,
  } = useInput({ validators: containValueValidator, defaultValue: goal?.text })

  const {
    value: type,
    handleSetValue: handleSetType,
    isValid: typeIsValid,
  } = useInput({ validators: containValueValidator, defaultValue: goal?.type })

  const {
    value: imageUrl,
    handleSetValue: handleSetImageUrl,
    isValid: imageUrlIsValid,
  } = useInput({ defaultValue: goal?.imageUrl })

  const {
    value: iconUrl,
    handleSetValue: handleSetIconUrl,
    isValid: iconUrlIsValid,
  } = useInput({ defaultValue: goal?.iconUrl })

  const {
    value: minutes,
    handleSetValue: handleSetMinutes,
    isValid: minutesIsValid,
  } = useInput({ defaultValue: goal?.minutes })

  const {
    value: description,
    handleSetValue: handleSetDescription,
    isValid: descriptionIsValid,
  } = useInput({ defaultValue: goal?.description })

  const handleClose = useCallback(() => {
    handleSetText('')
    handleSetImageUrl('')
    handleSetDescription('')
    handleSetMinutes('')
    handleSetType('')
    handleSetIconUrl('')

    onClose()
  }, [
    handleSetText,
    handleSetMinutes,
    handleSetIconUrl,
    handleSetType,
    handleSetDescription,
    onClose,
    handleSetImageUrl,
  ])

  const isSaveEnabled = useMemo(() => textIsValid && type, [textIsValid, type])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!isSaveEnabled) return

      const newGoal: LessonGoalType = {
        id,
        text,
        imageUrl,
        description,
        type,
        iconUrl,
        minutes,
      }

      onSave(newGoal)

      return handleClose()
    },
    [onSave, isSaveEnabled, text, imageUrl, iconUrl, id, description, type, minutes, handleClose],
  )

  const buttons = useMemo(
    () => [
      {
        text: 'Отменить',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: handleClose,
      },
      {
        text: isEdit ? 'Save' : 'Save goal',
        variant: 'contained' as const,
        color: isSaveEnabled ? ('blue' as const) : ('disabled' as const),
        disabled: !isSaveEnabled,
        onClick: handleSubmit,
      },
    ],
    [handleClose, isEdit, isSaveEnabled, handleSubmit],
  )

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" fullWidth open={isOpen}>
      <Box p={2}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <CourseImage {...(imageUrl ? { as: 'img', src: imageUrl } : {})} />
          <Box py={2}>
            <TextField
              fullWidth
              label="Изображение цели"
              value={imageUrl}
              onChange={handleSetImageUrl}
              error={!imageUrlIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Иконка цели"
              value={iconUrl}
              onChange={handleSetIconUrl}
              error={!iconUrlIsValid}
              variant="outlined"
            />
          </Box>

          <Box py={2}>
            <TextField
              fullWidth
              label="Тип эвента (название) - отображается в плашке цели во флоу"
              value={type}
              onChange={handleSetType}
              error={!typeIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Текст"
              required
              value={text}
              onChange={handleSetText}
              error={!textIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Время,мин"
              value={minutes}
              onChange={handleSetMinutes}
              error={!minutesIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Описание"
              value={description}
              onChange={handleSetDescription}
              error={!descriptionIsValid}
              variant="outlined"
            />
          </Box>

          <ButtonsGroup buttons={buttons} />
        </form>
      </Box>
    </Dialog>
  )
})
const CourseImage = styled(Box)`
  border-radius: 24px;
  overflow: hidden;
  max-height: 375px;
  width: 100%;
`
