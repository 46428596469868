import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { AddMessageOrGroup } from '~/components/add-message-or-group'
import { CustomButton } from '~/components/button'
import { Message } from '~/components/message'
import { Direction } from '~/domain/types'
import { insertAndShift } from '~/helpers/insert-and-shift'
import { CloseGoalModal } from '~/modals/close-goal-modal'
import { MessageModal } from '~/modals/message-modal/message-modal'
import { useCommonStores } from '~/store/common'
import { IMessage, IMessageGroup, LessonGoalType, MESSAGES_TYPES } from '~/types/course'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteIcon from '@mui/icons-material/Delete'
import HistoryIcon from '@mui/icons-material/History'
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'

interface MessageGroupProps {
  group: IMessageGroup
  groupNumber: number
  onDeleteGroup: () => void
  onUpdateGroup: (group: IMessageGroup) => void
  onMoveGroup: (groupId: string | number, position: Direction) => void
  onCreateGroupWithMessage: (message: IMessage, index: number | string) => void
  onCreateGroupBetweenGroups: (index: number) => void
  onMoveMessageBetweenGroups: (message: IMessage, groupId: number | string, direction: Direction) => boolean | undefined
  onToggleUserHistoryEditor: () => void
  goals?: LessonGoalType[]
}

export const MessageGroup: FunctionComponent<MessageGroupProps> = ({
  group,
  groupNumber,
  onDeleteGroup,
  onUpdateGroup,
  onMoveGroup,
  onCreateGroupWithMessage,
  onMoveMessageBetweenGroups,
  onCreateGroupBetweenGroups,
  onToggleUserHistoryEditor,
  goals,
}) => {
  const { messages } = group

  const {
    dialogStore: { showDialog },
  } = useCommonStores()

  const [messageModalIsOpen, setMessageModalIsOpen] = useState(false)
  const [messageIndex, setMessageIndex] = useState<number | null>(null)
  const [isShow, setIsShow] = useState(false)

  const [closedGoal, setClosedGoal] = useState('')

  const [filteredGoalsEventList, setFilteredGoalsEventList] = useState<LessonGoalType[]>([])

  const [filteredCloseGoalsList, setFilteredCloseGoalsList] = useState<LessonGoalType[]>([])

  const changeFilteredGoalsEventList = useCallback(
    (closeGoalId: string) => {
      const filteredGoals = goals?.filter(item => item.id !== closeGoalId)
      setFilteredGoalsEventList(filteredGoals || [])
    },
    [goals],
  )

  const changeCloseGoal = useCallback(
    (closeGoalId: string) => {
      const goal = goals?.find(item => item.id === closeGoalId)
      goal && setClosedGoal(goal.text)
    },
    [goals],
  )

  useEffect(() => {
    const closedGoalsIdsSet = new Set()

    if (group.closeGoals) {
      const closeGoalId = group?.closeGoals[0]

      if (closeGoalId) {
        changeCloseGoal(closeGoalId)
        changeFilteredGoalsEventList(closeGoalId)
        closedGoalsIdsSet.add(closeGoalId)
      }
    } else {
      setFilteredGoalsEventList(goals || [])
    }
    if (goals) {
      //нельзя закрыть цели, которые уже выбраны в качестве GOAL_EVENT
      group.messages
        .filter(item => item.type === MESSAGES_TYPES.GOAL_EVENT)
        .forEach(filteredMessages => {
          filteredMessages.type === MESSAGES_TYPES.GOAL_EVENT && closedGoalsIdsSet.add(filteredMessages.targetGoalId)
        })

      const blockedIds = Array.from(closedGoalsIdsSet.values())
      const filteredIds = goals.filter(item => !blockedIds.includes(item.id))

      setFilteredCloseGoalsList(filteredIds)
    }
  }, [group, goals, changeCloseGoal, changeFilteredGoalsEventList])

  const handleCloseChapterModal = useCallback(() => {
    setMessageModalIsOpen(false)
    setMessageIndex(null)
  }, [])

  const handleCreateMessageButtonClick = useCallback(
    (messageId?: number | string) => {
      setMessageModalIsOpen(true)

      if (messageId) {
        const index = messages.findIndex(msg => msg.id === messageId)
        setMessageIndex(index)
      }
    },
    [messages],
  )

  const handleCreateMessage = useCallback(
    (message: IMessage) => {
      if (messageIndex) {
        messages?.splice(messageIndex, 0, message)
        const updatedGroup = { ...group, messages }

        onUpdateGroup(updatedGroup)
        return
      }

      const updatedGroup = { ...group, messages: [...(messages || []), message] }

      onUpdateGroup(updatedGroup)
    },
    [group, messageIndex, messages, onUpdateGroup],
  )

  const handleUpdateMessage = useCallback(
    (updatedMessage: IMessage) => {
      const updatedGroup = {
        ...group,
        messages: messages.map(message => (message.id === updatedMessage.id ? updatedMessage : message)),
      }
      onUpdateGroup(updatedGroup)
    },
    [group, messages, onUpdateGroup],
  )

  const handleDeleteMessage = useCallback(
    (id: string) => {
      // eslint-disable-next-line no-restricted-globals
      const isConfirmed = confirm('Вы уверены, что хотите удалить сообщение?')
      if (!isConfirmed) return

      const updatedGroup = {
        ...group,
        messages: messages.filter(message => message.id !== id),
      }
      onUpdateGroup(updatedGroup)
    },
    [group, messages, onUpdateGroup],
  )

  const handleCloseGoals = (id: string) => {
    const updatedGroup = { ...group, closeGoals: [id] }
    onUpdateGroup(updatedGroup)

    changeCloseGoal(id)

    const newCloseGoalsList = [...filteredCloseGoalsList].filter(item => item.id !== id)

    setFilteredCloseGoalsList(newCloseGoalsList)

    changeFilteredGoalsEventList(id)
  }

  const handleOpenCloseGoalModal = () => {
    showDialog(<CloseGoalModal goals={filteredCloseGoalsList} onSave={handleCloseGoals} />, {
      title: 'Закрыть цель',
    })
  }

  const handleMoveMessage = useCallback(
    (message: IMessage, direction: Direction) => {
      const index = messages.findIndex(msg => msg.id === message.id)
      const totalMessages = messages.length - 1

      const isFirstMessage = direction === Direction.UP && index === 0
      const isLastMessage = direction === Direction.DOWN && index === totalMessages

      if (isFirstMessage || isLastMessage) return

      const indexOfPreviousElement = index - 1
      const indexOfNextElement = index + 1

      const result =
        direction === Direction.UP
          ? insertAndShift(messages, index, indexOfPreviousElement)
          : insertAndShift(messages, index, indexOfNextElement)

      const updatedGroup = {
        ...group,
        messages: result,
      }
      onUpdateGroup(updatedGroup)
    },
    [group, messages, onUpdateGroup],
  )

  const handleMoveToSeparateGroup = useCallback(
    (message: IMessage) => {
      onCreateGroupWithMessage(message, group.id)

      const updatedGroup = {
        ...group,
        messages: messages.filter(msg => msg.id !== message.id),
      }
      onUpdateGroup(updatedGroup)
    },
    [group, messages, onCreateGroupWithMessage, onUpdateGroup],
  )

  const handleMoveMessageBetweenGroups = useCallback(
    (message: IMessage, direction: Direction) => {
      onMoveMessageBetweenGroups(message, group.id, direction)
    },
    [group, onMoveMessageBetweenGroups],
  )

  return (
    <>
      <Card
        variant="outlined"
        sx={{ position: 'relative', overflow: 'inherit' }}
        onMouseEnter={() => setIsShow(true)}
        onMouseLeave={() => setIsShow(false)}
      >
        <AddMessageOrGroup isDisplay={isShow} onClick={() => onCreateGroupBetweenGroups(groupNumber)} />
        <CardHeader
          title={`Группа ${groupNumber + 1}`}
          action={
            <>
              <ButtonGroup size="small" variant="outlined" aria-label="outlined button group" sx={{ mr: 1 }}>
                <Button onClick={onToggleUserHistoryEditor}>
                  <HistoryIcon />
                </Button>
              </ButtonGroup>
              <ButtonGroup size="small" variant="outlined" aria-label="outlined button group" sx={{ mr: 1 }}>
                <Button onClick={onDeleteGroup}>
                  <DeleteIcon />
                </Button>
              </ButtonGroup>
              <ButtonGroup size="small" variant="outlined" aria-label="outlined button group">
                <Button onClick={() => onMoveGroup(group.id, Direction.UP)}>
                  <ArrowUpwardIcon />
                </Button>
                <Button onClick={() => onMoveGroup(group.id, Direction.DOWN)}>
                  <ArrowDownwardIcon />
                </Button>
              </ButtonGroup>
            </>
          }
        />
        {messages.length > 0 && (
          <CardContent>
            {messages.map(message => (
              <Message
                goals={filteredGoalsEventList}
                key={message.id}
                message={message}
                onDeleteMessage={() => handleDeleteMessage(message.id)}
                onUpdateMessage={handleUpdateMessage}
                onMoveMessage={handleMoveMessage}
                onMoveToSeparateGroup={handleMoveToSeparateGroup}
                onMoveMessageBetweenGroups={handleMoveMessageBetweenGroups}
                onCreateMessageButtonClick={handleCreateMessageButtonClick}
              />
            ))}
          </CardContent>
        )}
        <CardActions>
          <Button size="large" startIcon={<AddCircleOutlineIcon />} onClick={() => handleCreateMessageButtonClick()}>
            Добавить сообщение в группу
          </Button>
        </CardActions>
        <Box display="flex" justifyContent="space-between" style={{ margin: '10px 20px 10px 20px' }}>
          <CustomButton variant="contained" onClick={handleOpenCloseGoalModal} color="green">
            <Typography>Закрыть цель</Typography>
          </CustomButton>
          {closedGoal && <Typography color="red">Цель: &quot; {closedGoal} &quot; закрыта</Typography>}
        </Box>
      </Card>
      {messageModalIsOpen && (
        <MessageModal
          isOpen={messageModalIsOpen}
          onClose={handleCloseChapterModal}
          onSave={handleCreateMessage}
          goals={filteredGoalsEventList}
        />
      )}
    </>
  )
}
