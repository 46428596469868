export enum UserRole {
  admin = 'ADMIN_ROLE',
  student = 'STUDENT_ROLE',
  editor = 'EDITOR_ROLE',
}

export interface IUserProps {
  id: string
  displayName: string | null
  email: string | null
  photoURL: string | null
  roles?: UserRole[]
}

class User {
  id: string
  displayName: string | null
  email: string | null
  photoURL: string | null
  roles?: UserRole[]

  constructor(userProps: IUserProps) {
    this.id = userProps.id
    this.displayName = userProps.displayName
    this.email = userProps.email
    this.photoURL = userProps.photoURL
    this.roles = userProps.roles ? User.checkAndGetRoles(userProps.roles) : [UserRole.student]
  }

  static checkAndGetRoles = (roles: UserRole[]) => {
    return roles.reduce((all: UserRole[], role: UserRole) => {
      const rolesByAdmin = role === UserRole.admin ? [UserRole.admin, UserRole.student] : [role]

      return [...all, ...rolesByAdmin]
    }, [])
  }

  static toRaw(user: User) {
    return {
      id: user.id,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      roles: user.roles,
    }
  }

  static fromRaw(rawUser: any) {
    return new User({
      id: rawUser.id,
      email: rawUser.email,
      displayName: rawUser.displayName,
      photoURL: rawUser.photoURL,
      roles: rawUser.roles,
    })
  }
}

export { User }
