import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { ButtonsGroup } from '~/components/buttons-group'
import { InputSelect } from '~/components/input-select'
import { Multiselect } from '~/components/multiselect'
import { userDataConstant } from '~/constants/droplet-user-data-constant'
import {
  CourseDropletType,
  dropletImages,
  dropletRegions,
  dropletSizes,
  DROPLET_IMAGES,
  DROPLET_REGION,
  DROPLET_SIZE,
} from '~/domain/course-droplet'
import { getUniqueId } from '~/helpers/id'
import { containValueValidator } from '~/helpers/validation'
import { useCourseRoute } from '~/hooks/use-course-route'
import { useInput } from '~/hooks/use-input'
import { Course } from '~/types/course'

import { Box, Dialog, Switch, TextField, Typography } from '@mui/material'

interface DropletModalProps {
  onClose: () => void
  isOpen: boolean
  droplet?: CourseDropletType
  onSave: (droplet: CourseDropletType) => void
  course?: Course
}

const defaultDropletName = `${DROPLET_IMAGES['ubuntu-20-04-x64']} ${DROPLET_REGION.ams3} ${DROPLET_SIZE['s-1vcpu-1gb']}`
const defaultTags = () => ['autocreated-vm', 'course-vm'].map(value => ({ label: value, value }))

export const DropletModal: FunctionComponent<DropletModalProps> = React.memo(props => {
  const { onClose, isOpen, droplet, onSave } = props
  const id = droplet?.id ?? getUniqueId()
  const isEdit = Boolean(droplet)
  const { id: courseId } = useCourseRoute()

  const {
    value: name,
    handleSetValue: handleSetName,
    isValid: nameIsValid,
  } = useInput({
    validators: containValueValidator,
    defaultValue: droplet?.name ?? `${defaultDropletName}`,
  })

  const { value: description, handleSetValue: handleSetDescription } = useInput({
    defaultValue: droplet?.description,
  })

  const { value: userData, handleSetValue: handleSetUserData } = useInput({
    validators: containValueValidator,
    defaultValue: droplet?.userData ?? userDataConstant,
  })

  const {
    value: region,
    handleSetValue: handleSetRegion,
    isValid: regionIsValid,
  } = useInput({
    defaultValue: droplet?.region ?? DROPLET_REGION.ams3,
    validators: containValueValidator,
  })

  const {
    value: username,
    handleSetValue: handleSetUsername,
    isValid: usernameIsValid,
  } = useInput({
    defaultValue: droplet?.credentials?.username ?? 'test',
    validators: containValueValidator,
  })

  const {
    value: password,
    handleSetValue: handleSetPassword,
    isValid: passwordIsValid,
  } = useInput({
    defaultValue: droplet?.credentials?.password ?? '12345678',
    validators: containValueValidator,
  })

  const {
    value: size,
    handleSetValue: handleSetSize,
    isValid: sizeIsValid,
  } = useInput({
    defaultValue: droplet?.size ?? DROPLET_SIZE['s-1vcpu-1gb'],
    validators: containValueValidator,
  })

  const {
    value: image,
    handleSetValue: handleSetImage,
    isValid: imageIsValid,
  } = useInput({
    defaultValue: droplet?.image ?? DROPLET_IMAGES['ubuntu-20-04-x64'],
    validators: containValueValidator,
  })

  const { value: tags, handleSetValue: handleSetTags } = useInput({
    defaultValue: droplet?.tags || (defaultTags() as any),
  })

  const { checked: monitoring, handleSetChecked: handleSetMonitoring } = useInput({
    defaultChecked: droplet?.monitoring,
  })
  const { checked: ipv6, handleSetChecked: handleSetIpv6 } = useInput({
    defaultChecked: droplet?.ipv6,
  })
  const { checked: backups, handleSetChecked: handleSetBackups } = useInput({
    defaultChecked: droplet?.backups,
  })

  const isSaveEnabled = useMemo(
    () => nameIsValid && regionIsValid && sizeIsValid && imageIsValid,
    [nameIsValid, imageIsValid, regionIsValid, sizeIsValid],
  )

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!isSaveEnabled) return

      const newUserMeta: CourseDropletType = {
        id,
        courseId: courseId ?? '',
        name,
        description,
        region,
        size,
        image,
        tags: tags?.map((item: any) => (typeof item === 'string' ? item : item.label)),
        ipv6,
        monitoring,
        backups,
        userData,
        credentials: {
          username,
          password,
        },
        createdAt: droplet?.createdAt,
      }

      onSave(newUserMeta)

      return onClose()
    },
    [
      courseId,
      droplet?.createdAt,
      id,
      userData,
      username,
      password,
      image,
      description,
      isSaveEnabled,
      name,
      onClose,
      onSave,
      region,
      size,
      backups,
      ipv6,
      monitoring,
      tags,
    ],
  )

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel, Back',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: onClose,
      },
      {
        text: isEdit ? 'Save' : 'Create Droplet',
        variant: 'contained' as const,
        color: 'blue' as const,
        disabled: !isSaveEnabled,
        onClick: handleSubmit,
      },
    ],
    [onClose, isEdit, isSaveEnabled, handleSubmit],
  )

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" fullWidth open={isOpen}>
      <Box p={2}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box py={2}>
            <TextField
              fullWidth
              label="Droplet Name (only a-z, A-Z, 0-9, . and -)"
              required
              value={name}
              onChange={handleSetName}
              error={!nameIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={1}>
            <InputSelect onChange={handleSetRegion} options={dropletRegions} label="Регион" value={region} />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Droplet access username (Be careful)"
              required
              value={username}
              onChange={handleSetUsername}
              error={!usernameIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Droplet first access password (Be careful)"
              required
              value={password}
              onChange={handleSetPassword}
              error={!passwordIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={1}>
            <InputSelect onChange={handleSetImage} options={dropletImages} label="ОС" value={image} />
          </Box>
          <Box py={1}>
            <InputSelect onChange={handleSetSize} options={dropletSizes} label="Железо" value={size} />
          </Box>
          <Box py={1}>
            <Multiselect value={tags} setValue={handleSetTags} options={defaultTags()} freeSolo />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Запускаемый скрипт перед созданием дроплета"
              placeholder="Например: #ChallengeResponseAuthentication yes\n#PasswordAuthentication yes\n#PasswordRoot 123456789\n"
              required
              multiline
              value={userData}
              onChange={handleSetUserData}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Description"
              required
              value={description}
              onChange={handleSetDescription}
              variant="outlined"
            />
          </Box>

          <Box py={1}>
            <Box component="label" display="flex" justifyContent="flex-start" alignItems="flex-start">
              <Switch checked={monitoring} onChange={handleSetMonitoring} color="primary" name="isFree" />
              <Box ml={2} mt={1}>
                <Typography>Мониторинг дроплета (бесплатно)</Typography>
              </Box>
            </Box>
          </Box>
          <Box py={1}>
            <Box component="label" display="flex" justifyContent="flex-start" alignItems="flex-start">
              <Switch checked={backups} onChange={handleSetBackups} color="primary" name="isFree" />
              <Box ml={2} mt={1}>
                <Typography>Бэкапы дроплета (5$/month)</Typography>
              </Box>
            </Box>
          </Box>
          <Box py={1}>
            <Box component="label" display="flex" justifyContent="flex-start" alignItems="flex-start">
              <Switch checked={ipv6} onChange={handleSetIpv6} color="primary" name="isFree" />
              <Box ml={2} mt={1}>
                <Typography>IPv6 (бесплатно)</Typography>
              </Box>
            </Box>
          </Box>

          <ButtonsGroup buttons={buttons} my={0} />
        </form>
      </Box>
    </Dialog>
  )
})
