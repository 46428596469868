import { UserFeedbackType } from '~/domain/user-feedback'
import { UserHistoryType } from '~/domain/user-histories'
import { getToken } from '~/utils/get-token'

import { POST } from './rest'

type GetUsersHistoriesResponse = {
  histories: UserHistoryType[]
}

type GetUsersFeedbackResponse = {
  feedback: UserFeedbackType[]
}

const getCourseUsersHistories = async (courseId: string): Promise<GetUsersHistoriesResponse> =>
  await POST('analytics-getCourseUsersHistories', { courseId }, getToken())

const getCourseUsersFeedback = async (courseId: string): Promise<GetUsersFeedbackResponse> =>
  await POST('analytics-getCourseUsersFeedback', { courseId }, getToken())

export const AnalyticsApi = {
  getCourseUsersHistories,
  getCourseUsersFeedback,
}
