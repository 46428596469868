import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { newPaymentParams, PaymentType } from '~/api/firebase/payment'
import { UserApi } from '~/api/firebase/user'
import { ButtonsGroup } from '~/components/buttons-group'
import { PaymentStatuses } from '~/domain/payment/payment'
import { PaymentProvider } from '~/domain/payment/providers/types'
import { getUniqueId } from '~/helpers/id'
import { paymentsStore } from '~/store'
import { Course } from '~/types/course'

import { Box, Dialog, TextField, Typography } from '@mui/material'

type Props = {
  onClose: () => void
  isOpen: boolean
  course?: Course
}

export type PaymentRow = {
  email: string
}

export const PaymentModal: FunctionComponent<Props> = React.memo(props => {
  const { onClose, isOpen, course } = props
  const [rows, setRows] = useState<PaymentRow[]>([{ email: '' }])
  const [isLoading, setLoading] = useState(false)

  const isSaveEnabled = useMemo(() => true, [])

  const handleAppendRow = () => {
    setRows(rows => [...(rows || []), { email: '' }])
  }

  const handleUpdateValue = useCallback((index: number, value: string) => {
    setRows(rows => rows?.map((prev, i) => (i === index ? { email: value } : prev)))
  }, [])

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      if (!isSaveEnabled || !course) return

      setLoading(true)
      for await (const row of rows) {
        const email = row.email

        if (!email) continue

        try {
          const user = await UserApi.fetchUserByEmail(email)

          if (!user?.length) {
            console.error(`User with email ${email} not exist`)
            continue
          }

          const paymentParams: newPaymentParams = {
            user: user[0],
            courseName: course.name,
            courseId: course.id,
            coursePriceAmount: 0,
            paymentProvider: PaymentProvider.fake,
            id: getUniqueId(),
            type: PaymentType.FULL,
            status: PaymentStatuses.CONFIRMED,
            paymentUrl: '',
            currency: course?.price?.currencyCode || 'USD',
            invoiceTime: Date.now(),
          }

          paymentsStore.create(paymentParams)
        } catch (error) {
          console.error(error, 'error')
        }
      }

      setLoading(false)

      return onClose()
    },
    [isSaveEnabled, rows, course, onClose],
  )

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel, Back',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: onClose,
      },
      {
        text: 'Создать платежи',
        variant: 'contained' as const,
        color: 'blue' as const,
        disabled: !isSaveEnabled || !rows?.length || isLoading,
        onClick: handleSubmit,
      },
    ],
    [onClose, isSaveEnabled, isLoading, rows?.length, handleSubmit],
  )

  const memoizedInputs = useMemo(() => {
    return (
      <>
        {rows?.map((item, index) => (
          <Box mb={2} key={index}>
            <TextField
              fullWidth
              label="Email"
              value={item.email}
              onChange={e => handleUpdateValue(index, e.target.value)}
              variant="outlined"
            />
          </Box>
        ))}
      </>
    )
  }, [rows, handleUpdateValue])

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" fullWidth open={isOpen}>
      <Box p={2}>
        <Box mb={2}>
          <Typography variant="h5">Добавьте почты, чтобы создать фейковые платежки за курс</Typography>
          <Typography variant="subtitle1">На данный момент доступ дается сразу за весь курс</Typography>
        </Box>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          {memoizedInputs}
          <Box mb={2}>
            <button disabled={isLoading} type="button" onClick={handleAppendRow}>
              Добавить еще
            </button>
          </Box>
          {isLoading && (
            <Box my={1}>
              <Typography variant="body1">Подождите, мы сохраняем данные</Typography>
            </Box>
          )}
          <ButtonsGroup buttons={buttons} my={0} />
        </form>
      </Box>
    </Dialog>
  )
})
