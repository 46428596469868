import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useState } from 'react'
import { CourseDroplet, CourseDropletType } from '~/domain/course-droplet'
import { DropletModal } from '~/modals/course-droplet-modal'
import { courseDropletsStore } from '~/store'

import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

type Column = {
  id: 'id' | 'name' | 'region' | 'size' | 'image' | 'description' | 'tags' | 'ipv6' | 'monitoring' | 'backups'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: Column[] = [
  {
    id: 'id',
    label: 'ID',
  },
  { id: 'name', label: 'Название', minWidth: 100 },
  {
    id: 'region',
    label: 'Регион',
    align: 'right',
  },
  {
    id: 'size',
    label: 'Железо',
  },
  {
    id: 'image',
    label: 'ОС',
  },
  {
    id: 'description',
    label: 'Описание',
  },
  {
    id: 'tags',
    label: 'Теги',
  },
  {
    id: 'ipv6',
    label: 'IPv6',
  },
  {
    id: 'monitoring',
    label: 'Мониторинг',
  },
  {
    id: 'backups',
    label: 'Бэкапы',
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    root: {
      width: '100%',
    },
    container: {
      minHeight: 200,
      maxHeight: '100vh',
    },
  }),
)

export const CourseDropletsTable = observer(() => {
  const classes = useStyles()
  const [userMetaModalIsVisible, setUserMetaModalIsVisible] = useState(false)
  const [userMeta, setUserMeta] = useState<CourseDropletType>()

  const courseUsersMeta = toJS(courseDropletsStore.courseDroplets)
  const handleUpdateUserMeta = useCallback((updatedUserMeta: CourseDropletType) => {
    const droplet = new CourseDroplet(updatedUserMeta)

    courseDropletsStore.updateDroplet(droplet)
  }, [])

  const handleCloseUserMetaModal = () => {
    setUserMetaModalIsVisible(false)
  }

  const handleEditMetaUserButtonClick = useCallback((droplet: CourseDropletType) => {
    setUserMetaModalIsVisible(true)
    setUserMeta(droplet)
  }, [])

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell key={'edit'} style={{ minWidth: 50 }}>
                  Edit
                </TableCell>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {courseUsersMeta?.map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key={row.id} style={{ minWidth: 100 }}>
                      <IconButton edge="end" onClick={() => handleEditMetaUserButtonClick(row)} size="large">
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    {columns.map(column => {
                      const value = row[column.id]
                      const isArray = Array.isArray(value)
                      const parsedValue = isArray ? (value as string[])?.map(item => item).join(', ') : value

                      if (typeof parsedValue === 'boolean') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {parsedValue ? 'Есть' : 'Нет'}
                          </TableCell>
                        )
                      }

                      if (column.format && typeof value === 'number') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format(value)}
                          </TableCell>
                        )
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {parsedValue}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {userMetaModalIsVisible && (
        <DropletModal
          onSave={handleUpdateUserMeta}
          onClose={handleCloseUserMetaModal}
          isOpen={userMetaModalIsVisible}
          droplet={userMeta}
        />
      )}
    </>
  )
})
