import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { getUniqueId } from '~/helpers/id'
import { QuizOption } from '~/modals/message-modal/message-modal'
import { IMessage } from '~/types/course'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, FormControl, TextField, Typography, Select, SelectChangeEvent, MenuItem } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { CommentFieldsProps } from './fields/comment-fields'

type QuizProps = {
  message?: IMessage
  quizTitle: string
  handleQuizTitle: (str: string) => void
  setQuizTitle: (str: string) => void
  quizOptions: QuizOption[]
  setQuizOptions: React.Dispatch<React.SetStateAction<QuizOption[]>>
  quizOptionType: string
  handleOptionType: (str: string) => void
  setOptionType: (str: string) => void
} & CommentFieldsProps

export const Quiz = ({
  message,
  successAnswer,
  wrongAnswer,
  quizTitle,
  handleQuizTitle,
  setQuizTitle,
  quizOptions,
  setQuizOptions,
  quizOptionType,
  handleOptionType,
  setOptionType,
}: QuizProps) => {
  const isCheckbox = quizOptionType === 'checkbox'

  useEffect(() => {
    if (!message) return

    if (message.type === 'QUIZ') {
      setOptionType(message.optionType)
      setQuizOptions(message.options)
      setQuizTitle(message.title)
      wrongAnswer?.setValue?.(message.wrongAnswer)
      successAnswer?.setValue?.(message.successAnswer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, setOptionType, setQuizOptions, setQuizTitle])

  const handleAddingOption = useCallback(() => {
    setQuizOptions(prevState => [
      ...prevState,
      {
        id: getUniqueId(),
        content: '',
        hint: '',
        isCorrect: false,
        isEditing: true,
      },
    ])
  }, [setQuizOptions])
  const handleDeletingOption = useCallback(
    (optionId: string) => {
      setQuizOptions(prevState => prevState.filter(opt => opt.id !== optionId))
    },
    [setQuizOptions],
  )
  const handleEditingOption = useCallback(
    (optionId: string) => {
      setQuizOptions(prevState => prevState.map(opt => ({ ...opt, isEditing: opt.id === optionId })))
    },
    [setQuizOptions],
  )
  const handleAcceptingChanges = useCallback(
    (optionId: string) => {
      setQuizOptions(prevState =>
        prevState.map(opt => {
          if (opt.id !== optionId) return opt
          return { ...opt, isEditing: false }
        }),
      )
    },
    [setQuizOptions],
  )
  const handleChangeOptionText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
      setQuizOptions(prevState =>
        prevState.map(opt => {
          if (opt.id !== id) return opt
          return { ...opt, content: e.target.value }
        }),
      )
    },
    [setQuizOptions],
  )

  const handleChangeOptionHint = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
      setQuizOptions(prevState =>
        prevState.map(opt => {
          if (opt.id !== id) return opt
          return { ...opt, hint: e.target.value }
        }),
      )
    },
    [setQuizOptions],
  )

  const handleChangeCorrectNotChoosenCheckbox = useCallback(
    (e: any, id: string) => {
      setQuizOptions(prevState =>
        prevState.map(opt => {
          if (opt.id !== id) return opt
          return { ...opt, notChoosenAndCorrectHint: e.target.value }
        }),
      )
    },
    [setQuizOptions],
  )

  const handleChangeOptionImageURL = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
      setQuizOptions(prevState =>
        prevState.map(opt => {
          if (opt.id !== id) return opt
          return { ...opt, imgUrl: e.target.value }
        }),
      )
    },
    [setQuizOptions],
  )

  const handleChangeOptionsAnswer = useCallback(
    (e: SelectChangeEvent<boolean>, id: string) => {
      setQuizOptions(prevState =>
        prevState.map(opt => {
          if (opt.id !== id) return opt
          return { ...opt, isCorrect: e.target.value === 'true' }
        }),
      )
    },
    [setQuizOptions],
  )

  return (
    <Box margin="16px 0">
      <TextField
        fullWidth
        label="Заголовок"
        value={quizTitle}
        onChange={e => handleQuizTitle(e.target.value)}
        variant="outlined"
      />

      {/* <CommentFields wrongAnswer={wrongAnswer} successAnswer={successAnswer} /> */}

      <Box my={2}>
        <Typography>Тип ответа:</Typography>
        <Select value={quizOptionType} onChange={e => handleOptionType(e.target.value as string)}>
          <MenuItem value="radio">Radio</MenuItem>
          <MenuItem value="checkbox">Checkbox</MenuItem>
        </Select>
      </Box>

      {quizOptions?.length > 0 && (
        <Box py={2} display="flex" alignItems="center">
          <FormControl fullWidth component="fieldset">
            {quizOptions.map(option => (
              <Card key={option.id}>
                <Box my={2}>
                  <TextField
                    value={option.content}
                    onChange={e => handleChangeOptionText(e, option.id)}
                    autoFocus
                    multiline
                    fullWidth
                    label="Текст ответа"
                    disabled={!option.isEditing}
                  />
                </Box>
                <Box my={2}>
                  <TextField
                    size="medium"
                    value={option.hint}
                    disabled={!option.isEditing}
                    onChange={e => handleChangeOptionHint(e, option.id)}
                    multiline
                    fullWidth
                    label="Подсказка"
                  />
                  {isCheckbox && option.isCorrect && (
                    <TextField
                      size="medium"
                      value={option.notChoosenAndCorrectHint}
                      disabled={!option.isEditing}
                      onChange={e => handleChangeCorrectNotChoosenCheckbox(e, option.id)}
                      multiline
                      fullWidth
                      style={{ marginTop: '20px' }}
                      label="Подсказка для правильного ответа, когда он не выбран"
                    />
                  )}
                </Box>
                <Box my={2}>
                  <TextField
                    size="medium"
                    value={option.imgUrl}
                    disabled={!option.isEditing}
                    onChange={e => handleChangeOptionImageURL(e, option.id)}
                    multiline
                    fullWidth
                    label="Ссылка на изображение(опционально)"
                  />
                </Box>
                <Box my={2}>
                  <Typography>Это правильный ответ?</Typography>
                  <Select
                    value={option.isCorrect}
                    disabled={!option.isEditing}
                    onChange={e => handleChangeOptionsAnswer(e, option.id)}
                  >
                    <MenuItem value="true">Да</MenuItem>
                    <MenuItem value="false">Нет</MenuItem>
                  </Select>
                </Box>
                <Box flex="0 0 auto">
                  {option.isEditing ? (
                    <IconButton
                      edge="end"
                      aria-label="accept"
                      onClick={() => handleAcceptingChanges(option.id)}
                      size="large"
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => handleEditingOption(option.id)}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  )}

                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeletingOption(option.id)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Card>
            ))}
          </FormControl>
        </Box>
      )}

      <Button size="small" startIcon={<AddCircleOutlineIcon />} onClick={handleAddingOption}>
        Добавить ответ
      </Button>
    </Box>
  )
}

const Card = styled(Box)`
  width: 100%;
  border: 1px solid silver;
  border-radius: 20px;
  padding: 1em;
  margin-top: 10px;
`
