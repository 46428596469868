import React, { useEffect, useCallback, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'
import { getUniqueId } from '~/helpers/id'
import { BlankOption } from '~/modals/message-modal/message-modal'
import { IMessage } from '~/types/course'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, TextField, Typography, Select, MenuItem, SelectChangeEvent } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { CommentFields, CommentFieldsProps } from '../fields/comment-fields'
import { BlanksToolbar, modules, formats } from './blanks-toolbar'
import { countBlanks } from './helpers'

type BlanksProps = {
  message?: IMessage
  blankTitle: string
  handleBlankTitle: (str: string) => void
  setBlankTitle: (str: string | undefined) => void
  blankState: string
  setBlankState: React.Dispatch<React.SetStateAction<string>>
  blankOptions: BlankOption[]
  setBlankOptions: React.Dispatch<React.SetStateAction<BlankOption[]>>
} & CommentFieldsProps

export const Blanks = ({
  message,
  successAnswer,
  wrongAnswer,
  blankTitle,
  handleBlankTitle,
  setBlankTitle,
  blankState,
  setBlankState,
  blankOptions,
  setBlankOptions,
}: BlanksProps) => {
  const [blankCount, setBlankCount] = useState(0)

  useEffect(() => {
    if (!message) return

    if (message.type === 'BLANK') {
      setBlankTitle(message.title)
      setBlankState(message.sourceCode)
      setBlankOptions(message.options)
      wrongAnswer?.setValue?.(message.wrongAnswer)
      successAnswer?.setValue?.(message.successAnswer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, setBlankState, setBlankTitle, setBlankOptions])

  useEffect(() => {
    setBlankCount(countBlanks(blankState))
  }, [blankState])

  const handleChange = (html: string) => {
    setBlankState(html)
  }

  const handleAddingOption = useCallback(() => {
    setBlankOptions(prevState => [
      ...prevState,
      {
        id: getUniqueId(),
        content: '',
        isEditing: true,
      },
    ])
  }, [setBlankOptions])

  const handleDeletingOption = useCallback(
    (optionId: string) => {
      setBlankOptions(prevState => prevState.filter(opt => opt.id !== optionId))
    },
    [setBlankOptions],
  )
  const handleEditingOption = useCallback(
    (optionId: string) => {
      setBlankOptions(prevState => prevState.map(opt => ({ ...opt, isEditing: opt.id === optionId })))
    },
    [setBlankOptions],
  )
  const handleAcceptingChanges = useCallback(
    (optionId: string) => {
      setBlankOptions(prevState =>
        prevState.map(opt => {
          if (opt.id !== optionId) return opt
          return { ...opt, isEditing: false }
        }),
      )
    },
    [setBlankOptions],
  )
  const handleChangeOptionText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
      setBlankOptions(prevState =>
        prevState.map(opt => {
          if (opt.id !== id) return opt
          return { ...opt, content: e.target.value }
        }),
      )
    },
    [setBlankOptions],
  )

  const handleChangeOptionsPosition = useCallback(
    (e: SelectChangeEvent<string | number>, id: string) => {
      const value = e.target.value as unknown as undefined | number | string
      setBlankOptions(prevState =>
        prevState.map(opt => {
          if (opt.id !== id) return opt
          return { ...opt, position: value === 'undefined' ? undefined : Number(value) }
        }),
      )
    },
    [setBlankOptions],
  )

  return (
    <Box mb={16}>
      <Box my={2}>
        <TextField
          fullWidth
          label="Заголовок"
          value={blankTitle}
          onChange={e => handleBlankTitle(e.target.value)}
          variant="outlined"
          style={{ marginTop: 10 }}
        />
      </Box>

      <CommentFields wrongAnswer={wrongAnswer} successAnswer={successAnswer} />
      <BlanksToolbar />
      <ReactQuill value={blankState} onChange={handleChange} modules={modules} formats={formats} />
      <Box my={4}>
        <Typography>Ответы</Typography>
        {blankOptions.map(option => (
          <Card key={option.id}>
            <Select
              value={option.position ?? 'undefined'}
              onChange={e => handleChangeOptionsPosition(e, option.id)}
              disabled={!option.isEditing}
            >
              <MenuItem value="undefined">Нет</MenuItem>
              {Array.from({ length: blankCount }, (_, i) => i + 1).map(index => (
                <MenuItem key={index} value={index}>
                  {index}
                </MenuItem>
              ))}
            </Select>
            <TextField
              value={option.content}
              onChange={e => handleChangeOptionText(e, option.id)}
              autoFocus
              multiline
              fullWidth
              label="Текст ответа"
              disabled={!option.isEditing}
            />

            <Box flex="0 0 auto">
              {option.isEditing ? (
                <IconButton edge="end" aria-label="accept" onClick={() => handleAcceptingChanges(option.id)}>
                  <CheckCircleIcon />
                </IconButton>
              ) : (
                <IconButton edge="end" aria-label="edit" onClick={() => handleEditingOption(option.id)}>
                  <EditIcon />
                </IconButton>
              )}

              <IconButton edge="end" aria-label="delete" onClick={() => handleDeletingOption(option.id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Card>
        ))}
        <Box my={2}>
          <Button size="small" startIcon={<AddCircleOutlineIcon />} onClick={handleAddingOption}>
            Добавить ответ
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

const Card = styled(Box)`
  width: 100%;
  display: inline-flex;
  border: 1px solid silver;
  border-radius: 20px;
  padding: 2em 1em;
  margin-top: 10px;
`
