import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { InnerLink } from '~/components/link'
import { SyncFireStoreBtn } from '~/components/sync-firestore-btn'
import { coursesContext } from '~/contexts/courses'
import { Course as CourseType, ACCESS_TYPES } from '~/types/course'

import { Box, Button, Card, CardActions, Typography } from '@mui/material'

type MouseEventType = React.MouseEvent<HTMLButtonElement, MouseEvent>
interface ICourseListItem {
  course: CourseType
}

const getCourseAccessTypeName = (type: ACCESS_TYPES) => {
  switch (type) {
    case ACCESS_TYPES.FREE:
      return 'free'
    case ACCESS_TYPES.PAID:
      return 'paid'
    case ACCESS_TYPES.SHAREWARE:
      return 'shareware'
    default:
      return ''
  }
}

export const CourseListItem = (props: ICourseListItem) => {
  const { course } = props
  const history = useHistory()
  const { handleRemoveCourse } = React.useContext(coursesContext)

  const handleRemove = React.useCallback(
    (e: MouseEventType, id: string) => {
      e.preventDefault()
      handleRemoveCourse(id)
    },
    [handleRemoveCourse],
  )

  const handleGoEditMetaInfo = React.useCallback(
    (e: MouseEventType, id: string) => {
      e.preventDefault()
      history.push(`/edit/${id}`)
    },
    [history],
  )

  const handleGoEditContentPage = React.useCallback(
    (e: MouseEventType, id: string) => {
      e.preventDefault()
      history.push(`/courses/${id}/course/edit`)
    },
    [history],
  )

  const features = useMemo(() => {
    let features = []

    if (course?.partialPaymentConfigId) {
      features.push('Payment Plan')
    }

    if (course?.price?.amountCents) {
      features.push('Full Price')
    }

    if (course?.discountPrice?.amountCents) {
      features.push('Discounted Price')
    }

    if (course?.isReady) {
      features.push('Available only for admins')
    }

    if (course?.chapters?.length) {
      features.push(`${course?.chapters?.length} chapter`)
    }

    if (course?.specializations?.length) {
      features.push(`Linked ${course?.specializations?.length} specialization`)
    }

    if (course?.skillset?.length) {
      features.push(`Linked ${course?.skillset?.length} skills`)
    }

    if (course?.courseUsersMeta?.length) {
      features.push(`Created ${course?.courseUsersMeta?.length} characters`)
    }

    const typeText = getCourseAccessTypeName(course?.accessType)

    if (course?.accessType && typeText) {
      features.push(`Access mode: ${typeText}`)
    }

    return features
  }, [course])

  return (
    <InnerLink key={course.id} width="100%" my={2} to={`/courses/${course.id}/course`}>
      <Card>
        <Box my={2}>
          {course?.imageUrl && (
            <Box px={2}>
              <CourseImage as="img" src={course?.imageUrl} />
            </Box>
          )}
          <Box px={2}>
            <Typography>{course.name}</Typography>
            <Features my={1} px={2} py={1}>
              <Typography variant="body2" component="p">
                Quest Settings:{' '}
                {features?.map((text, index) => (
                  <Typography key={text} variant="body2" component="span">
                    {text}
                    {index < features?.length - 1 ? ', ' : ''}
                  </Typography>
                ))}
              </Typography>
            </Features>
          </Box>
          <CardActions>
            <Button variant="outlined" size="small" onClick={e => handleGoEditMetaInfo(e, course.id)}>
              Quest Details
            </Button>
            <Button variant="outlined" size="small" onClick={e => handleGoEditContentPage(e, course.id)}>
              Content
            </Button>
            <Button variant="outlined" size="small" onClick={e => handleRemove(e, course.id)} color="error">
              Delete
            </Button>
            <SyncFireStoreBtn course={course} />
          </CardActions>
        </Box>
      </Card>
    </InnerLink>
  )
}

const CourseImage = styled(Box)`
  border-radius: 24px;
  overflow: hidden;
  max-height: 130px;
`

const Features = styled(Box)`
  background-color: #ffecb8;
`
