import debounce from 'lodash/debounce'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { UserApi } from '~/api/firebase/user'
import { UserHistoryApi } from '~/api/firebase/user-histories'
import { User } from '~/domain/user'
import { UserHistory } from '~/domain/user-histories'
import { Chapter, LessonType } from '~/types/course'

import { Autocomplete, Box, Dialog, FormControl, TextField, Typography } from '@mui/material'

import { CustomButton } from '../button'
import { computeHistories } from './helpers/histories'

type Props = {
  courseId?: string
  chapterId?: string
  lessonId?: string
  groupId?: string
  lessons: LessonType[] | undefined
  chapters: Chapter[] | null | undefined
  onToggleModal: () => void
  isModalOpen: boolean
}

export const EditUserHistoryModal = ({
  courseId,
  groupId,
  chapters,
  lessonId,
  chapterId,
  onToggleModal,
  isModalOpen,
}: Props) => {
  const [value, setValue] = useState('')
  const [user, setUser] = useState<User | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const { data, isLoading, isFetching } = useQuery(
    `user-email-${value}`,
    async () => await UserApi.fetchUserByEmail(value?.toLowerCase() || ''),
    {
      enabled: !!value && value?.length > 3,
      retry: false,
      refetchOnWindowFocus: false,
    },
  )

  const {
    data: history,
    isLoading: isHistoryLoading,
    isFetching: isHistoryFetching,
  } = useQuery(
    `user-history-${user?.id}`,
    async () => await UserHistoryApi.fetchUserHistory(user?.id || '', courseId || ''),
    {
      enabled: !!user && !!user?.id && !!courseId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  )

  const isDisabled = isLoading || isFetching || isHistoryLoading || isHistoryFetching || !value || !user || loading

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const histories = computeHistories({ chapterId, lessonId, chapters, groupId })

    const updatedAt = Date.now()

    if (!courseId || !chapterId || !user || !histories) {
      return
    }

    const newHistory = new UserHistory({
      id: history?.id,
      userId: user.id,
      createdAt: history?.createdAt || updatedAt,
      courseId,
      histories,
      updatedAt,
    })

    try {
      setLoading(true)
      await UserHistoryApi.createOrUpdateUserHistory(newHistory)
      setLoading(false)
      handleClose()
    } catch (ex) {
      console.error(ex)
      alert('Произошла ошибка при попытке сохранить новый прогресс, ошибка в консоле => F12 => Console(Консоль)')
      setLoading(false)
    }
  }

  const handleInput = debounce((e: any) => {
    setValue(e.target.value)
  }, 1000)

  const handleChange = (_: React.SyntheticEvent, value: any) => {
    setUser(value)
  }

  const handleClose = () => {
    setValue('')
    setUser(undefined)

    onToggleModal()
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" fullWidth open={isModalOpen}>
      <Box p={2} pb={0}>
        <Typography>Выберите пользователя и сохраните (Это изменит прохождение курса для юзера)</Typography>
      </Box>
      <Box p={2}>
        <form onSubmit={handleSubmit}>
          <Box py={2}>
            <FormControl fullWidth>
              <Autocomplete
                onInput={handleInput}
                onChange={handleChange}
                id="user-search"
                options={data || []}
                loading={isFetching || isLoading}
                getOptionLabel={option => option?.email ?? ''}
                value={user}
                renderInput={params => <TextField {...params} label="Full email" />}
              />
            </FormControl>
          </Box>
          <CustomButton type="submit" variant="contained" color="blue" disabled={isDisabled}>
            Изменить историю
          </CustomButton>
        </form>
      </Box>
    </Dialog>
  )
}
