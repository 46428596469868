import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { PartialPaymentConfigApi } from '~/api/firebase/partial-payment-config'
import { SpecializationApi } from '~/api/firebase/specializations'
import { SkillApi } from '~/api/skills'
import { AuthorMetaField } from '~/components/author-meta-field'
import { ButtonsGroup } from '~/components/buttons-group'
import { Header } from '~/components/header'
import { coursesContext } from '~/contexts/courses'
import { PartialPaymentConfigType } from '~/domain/partial-payment-config'
import { SKILL_NEST_TYPE, SkillType } from '~/domain/skills'
import { SpecializationType } from '~/domain/specializations'
import { getUniqueId } from '~/helpers/id'
import { containValueNeedMoreThanZeroValidator, containValueValidator } from '~/helpers/validation'
import { useAuthorMetaInput } from '~/hooks/use-author-meta-input'
import { useInput } from '~/hooks/use-input'
import { DefaultLayout } from '~/layouts/default-layout'
import { ACCESS_TYPES, Course } from '~/types/course'

import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
  Chip,
} from '@mui/material'

import { PartialPaymentsList } from './partial-payments-list'

interface CourseMainInfoFormProps {
  course?: Course
  onSave: (course: Course) => void
}

const preventSubmitOnEnter = (event: React.KeyboardEvent<HTMLFormElement>) => {
  if (event.code === 'Enter') {
    event.preventDefault()
  }
}

export const CourseMainInfoForm: FunctionComponent<CourseMainInfoFormProps> = ({ course, onSave }) => {
  const [specializations, setSpecializations] = useState<SpecializationType[]>([])
  const [selectedSpecializationsId, setSelectedSpecializationsId] = useState<string[]>(course?.specializations ?? [])
  const [skills, setSkills] = useState<SkillType[]>([])
  const [selectedSkills, setSelectedSkills] = useState<SkillType[]>([])
  const [accessType, setAccessType] = useState<ACCESS_TYPES>(ACCESS_TYPES.SHAREWARE)
  const [difficulty, setDifficulty] = useState<string>(course?.difficulty || 'easy')
  const [isPartialPaymentEnabled, setIsPartialPaymentEnabled] = useState(false)
  const [isRemoveConfig, setRemoveConfig] = useState(false)
  const [currentPartialPaymentConfig, setCurrentPartialPaymentConfig] = useState<PartialPaymentConfigType>()
  const [sponsors, setSponsors] = useState<string[]>(course?.sponsors || [])
  const [sponsorValue, setSponsorValue] = useState('')
  const history = useHistory()
  const { courses } = useContext(coursesContext)
  const isCreateMode = !course

  const { fullAuthorObject, authorValue, handleSetAuthor } = useAuthorMetaInput(
    course?.author ? `${course.author?.firstName} ${course.author?.lastName}` : undefined,
  )
  const isFree = useMemo(() => accessType === ACCESS_TYPES.FREE, [accessType])

  useEffect(() => {
    course?.accessType && setAccessType(course?.accessType)
    course?.difficulty && setDifficulty(course.difficulty)
  }, [course?.accessType, course?.difficulty])

  const {
    value: nameValue,
    handleSetValue: handleSetName,
    isValid: nameValuIsValid,
    error: nameError,
  } = useInput({ defaultValue: course?.name, validators: containValueValidator })

  const { value: uuidValue, isValid: uuidValuIsValid } = useInput({
    defaultValue: course?.id || getUniqueId(),
    validators: containValueValidator,
  })

  const {
    value: slug,
    handleSetValue: handleSetSlug,
    isValid: slugIsValid,
    error: slugError,
  } = useInput({ defaultValue: course?.slug, validators: containValueValidator })

  const slugIsUnique = useMemo(
    () =>
      courses.filter(item => item.id !== uuidValue).every(course => course.slug?.toLowerCase() !== slug?.toLowerCase()),
    [courses, slug, uuidValue],
  )

  const { value: descriptionValue, handleSetValue: handleSetDescription } = useInput({
    defaultValue: course?.description,
  })

  const { checked: isReady, handleSetChecked: handleSetReady } = useInput({ defaultChecked: course?.isReady })

  const { amountCents } = course?.price || {}
  const { amountCents: discountAmountCents } = course?.discountPrice || {}
  const {
    value: price,
    handleSetValue: setPrice,
    disabled: priceDisabled,
    isValid: priceIsValid,
    error: priceError,
  } = useInput({
    defaultValue: amountCents ? amountCents / 100 : '',
    validators: [...containValueValidator, ...containValueNeedMoreThanZeroValidator],
  })

  const {
    value: discountPrice,
    handleSetValue: setDiscountPrice,
    disabled: discountPriceDisabled,
    isValid: discountPriceIsValid,
    error: discountPriceError,
  } = useInput({
    defaultValue: discountAmountCents ? discountAmountCents / 100 : '',
    validators: containValueNeedMoreThanZeroValidator,
  })

  const {
    value: imageUrl,
    handleSetValue: handleSetImageUrl,
    isValid: imageUrlIsValid,
  } = useInput({ defaultValue: course?.imageUrl })

  const {
    value: paymentCount,
    handleSetValue: setPaymentCount,
    isValid: paymentCountIsValid,
    error: paymentCountError,
  } = useInput({ validators: [...containValueValidator, ...containValueNeedMoreThanZeroValidator] })

  const {
    value: pricePerPayment,
    handleSetValue: setPricePerPayment,
    isValid: pricePerPaymentIsValid,
    error: pricePerPaymentError,
  } = useInput({ validators: [...containValueValidator, ...containValueNeedMoreThanZeroValidator] })

  const {
    value: modulesPerPayment,
    handleSetValue: setModulesPerPayment,
    isValid: modulesPerPaymentIsValid,
    error: modulesPerPaymentError,
  } = useInput({ validators: [...containValueValidator, ...containValueNeedMoreThanZeroValidator] })

  const isPriceInvalid = !isFree ? !priceIsValid : false
  const isDiscountPriceInvalid = !isFree ? !discountPriceIsValid : false

  useEffect(() => {
    const getAllSpecializations = async () => {
      const result = await SpecializationApi.getAllSpecializations()
      setSpecializations(result)
    }

    getAllSpecializations()
  }, [])

  useEffect(() => {
    const getAllSkills = async () => {
      const result = await SkillApi.getSkillsByType(SKILL_NEST_TYPE.SKILL)
      const selectedSkills = result?.filter(skill => course?.skillset?.includes(skill?.id))

      setSkills(result)
      setSelectedSkills(selectedSkills)
    }

    getAllSkills()
  }, [course?.skillset])

  useEffect(() => {
    const request = async () => {
      if (course?.partialPaymentConfigId) {
        const config = await PartialPaymentConfigApi.fetchPaymentConfig(course?.partialPaymentConfigId)

        config && setCurrentPartialPaymentConfig(config)
      }
    }

    request()
  }, [course?.partialPaymentConfigId])

  const partialIsValid = isPartialPaymentEnabled
    ? paymentCountIsValid && pricePerPaymentIsValid && modulesPerPaymentIsValid
    : true

  const partialIsNew = useMemo(() => {
    if (!isPartialPaymentEnabled) return false

    if (partialIsValid) {
      if (
        paymentCount !== currentPartialPaymentConfig?.paymentCount ||
        (pricePerPayment || 1) * 100 !== currentPartialPaymentConfig?.pricePerPayment?.amountCents ||
        modulesPerPayment !== currentPartialPaymentConfig?.modulesPerPayment
      ) {
        return true
      }

      return false
    }

    return false
  }, [
    currentPartialPaymentConfig,
    paymentCount,
    pricePerPayment,
    modulesPerPayment,
    isPartialPaymentEnabled,
    partialIsValid,
  ])

  const isSaveEnabled =
    nameValuIsValid && uuidValuIsValid && slugIsValid && !isPriceInvalid && slugIsUnique && accessType && partialIsValid

  const isPartialEmpty = isPartialPaymentEnabled ? !partialIsValid : isRemoveConfig

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()

      if (!isSaveEnabled) return

      const config = partialIsNew
        ? await PartialPaymentConfigApi.createConfig({
            courseId: uuidValue,
            paymentCount,
            pricePerPayment: {
              amountCents: pricePerPayment * 100,
              currencyCode: 'USD',
            },
            modulesPerPayment,
          })
        : { id: course?.partialPaymentConfigId }

      onSave({
        id: uuidValue,
        slug: slug,
        price: {
          amountCents: price * 100,
          currencyCode: 'USD',
        },
        discountPrice: discountPrice
          ? {
              amountCents: discountPrice * 100,
              currencyCode: 'USD',
            }
          : null,
        partialPaymentConfigId: isPartialEmpty ? null : config?.id || null,
        simulatorVersion: 1,
        version: course?.version || '0.0.1',
        name: nameValue,
        specializations: selectedSpecializationsId,
        skillset: selectedSkills.map(skill => skill?.id ?? ''),
        accessType: accessType,
        description: descriptionValue,
        difficulty,
        ...(fullAuthorObject ? { author: fullAuthorObject } : {}),
        imageUrl,
        isReady,
        sponsors,
      })

      return history.push(`/${uuidValue}`)
    },
    [
      partialIsNew,
      isPartialEmpty,
      modulesPerPayment,
      paymentCount,
      pricePerPayment,
      discountPrice,
      isSaveEnabled,
      course,
      onSave,
      uuidValue,
      slug,
      price,
      imageUrl,
      nameValue,
      selectedSpecializationsId,
      selectedSkills,
      descriptionValue,
      fullAuthorObject,
      isReady,
      history,
      accessType,
      difficulty,
      sponsors,
    ],
  )

  const handleBack = useCallback(() => history.replace('/'), [history])

  const handleChangeConfig = useCallback(() => {
    if (course?.partialPaymentConfigId && currentPartialPaymentConfig) {
      setPaymentCount(currentPartialPaymentConfig?.paymentCount)
      setPricePerPayment(currentPartialPaymentConfig?.pricePerPayment?.amountCents / 100)
      setModulesPerPayment(currentPartialPaymentConfig?.modulesPerPayment)
    }

    setIsPartialPaymentEnabled(true)
  }, [
    course?.partialPaymentConfigId,
    currentPartialPaymentConfig,
    setModulesPerPayment,
    setPricePerPayment,
    setPaymentCount,
  ])

  const handleRemoveCourseConfig = useCallback(() => {
    setRemoveConfig(true)
    setCurrentPartialPaymentConfig(undefined)
  }, [])

  const handleClearPartialFields = useCallback(() => {
    setPaymentCount('')
    setPricePerPayment('')
    setModulesPerPayment('')
  }, [setPaymentCount, setPricePerPayment, setModulesPerPayment])

  const partialButtons = useMemo(() => {
    const removeConfigProp = course?.partialPaymentConfigId
      ? {
          text: 'Удалить конфигурацию',
          type: 'button' as const,
          variant: 'contained' as const,
          color: 'white' as const,
          onClick: handleRemoveCourseConfig,
        }
      : undefined

    const clearConfigProp =
      paymentCountIsValid || pricePerPaymentIsValid || modulesPerPaymentIsValid
        ? {
            text: 'Очистить',
            type: 'button' as const,
            variant: 'contained' as const,
            color: 'white' as const,
            onClick: handleClearPartialFields,
          }
        : undefined

    if (isPartialPaymentEnabled) {
      return [
        {
          text: 'Отмена',
          type: 'button' as const,
          variant: 'contained' as const,
          color: 'white' as const,
          onClick: () => {
            handleClearPartialFields()
            setIsPartialPaymentEnabled(false)
          },
        },
        ...(clearConfigProp ? [clearConfigProp] : []),
      ]
    }

    if (!isPartialPaymentEnabled) {
      return [
        {
          text: course?.partialPaymentConfigId ? 'Edit Configuration' : 'Create Configuration',
          type: 'button' as const,
          variant: 'contained' as const,
          color: 'blue' as const,
          onClick: handleChangeConfig,
        },
        ...(removeConfigProp ? [removeConfigProp] : []),
      ]
    }

    return []
  }, [
    paymentCountIsValid,
    pricePerPaymentIsValid,
    modulesPerPaymentIsValid,
    course?.partialPaymentConfigId,
    isPartialPaymentEnabled,
    handleChangeConfig,
    handleRemoveCourseConfig,
    handleClearPartialFields,
  ])

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel, Back',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: handleBack,
      },
      {
        text: isCreateMode ? 'Create Course' : 'Save Changes',
        variant: 'contained' as const,
        color: isSaveEnabled ? ('blue' as const) : ('disabled' as const),
        disabled: !isSaveEnabled,
        onClick: handleSubmit,
      },
    ],
    [isSaveEnabled, handleBack, handleSubmit, isCreateMode],
  )

  const handleSpecializationChange = (e: SelectChangeEvent<typeof selectedSpecializationsId>) => {
    const value = e.target.value
    setSelectedSpecializationsId(typeof value === 'string' ? value.split(',') : value)
  }

  //const handleSkillChange = (e: SelectChangeEvent<typeof selectedSkillsId>) => {
  //  const value = e.target.value
  //  setSelectedSkillsId(typeof value === 'string' ? value.split(',') : value)
  //}

  const handleChange = (_event: React.SyntheticEvent, newValue: SkillType[]) => {
    setSelectedSkills(newValue)
  }

  const accessItems = Object.values(ACCESS_TYPES).map(item => (
    <MenuItem key={item} value={item}>
      {item}
    </MenuItem>
  ))

  const difficultyItems = ['easy', 'medium', 'hard'].map(item => (
    <MenuItem key={item} value={item}>
      {item.charAt(0).toUpperCase() + item.slice(1)}
    </MenuItem>
  ))

  const partialPaymentProps = useMemo(() => {
    const props = {
      chaptersLength: course?.chapters?.length ?? 0,
      paymentCount: isPartialPaymentEnabled ? paymentCount && +paymentCount : currentPartialPaymentConfig?.paymentCount,
      pricePerPayment: isPartialPaymentEnabled
        ? pricePerPayment && +pricePerPayment
        : (currentPartialPaymentConfig?.pricePerPayment?.amountCents || 100) / 100,
      modulesPerPayment: isPartialPaymentEnabled
        ? modulesPerPayment && +modulesPerPayment
        : currentPartialPaymentConfig?.modulesPerPayment,
    }

    const hasProblem = Object.values(props).reduce((hasProblem, value) => {
      if (hasProblem) return true
      if ((!value && value !== 0) || isNaN(value)) return true

      return false
    }, false)

    return hasProblem ? undefined : props
  }, [
    isPartialPaymentEnabled,
    course?.chapters,
    modulesPerPayment,
    pricePerPayment,
    paymentCount,
    currentPartialPaymentConfig,
  ])

  const handleAddSponsor = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      const newSponsor = sponsorValue.trim()
      if (newSponsor && !sponsors.includes(newSponsor)) {
        setSponsors([...sponsors, newSponsor])
        setSponsorValue('')
      }
    }
  }

  const handleDeleteSponsor = (sponsorToDelete: string) => () => {
    setSponsors(sponsors.filter(sponsor => sponsor !== sponsorToDelete))
  }

  return (
    <DefaultLayout headerComponent={<Header title={isCreateMode ? 'Create Course' : 'Edit Quest'} />}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
        <CourseImage {...(imageUrl ? { as: 'img', src: imageUrl } : {})} />
        <Box py={2}>
          <TextField
            fullWidth
            label="Quest Image"
            value={imageUrl}
            onChange={handleSetImageUrl}
            required
            variant="outlined"
            error={!imageUrlIsValid}
          />
        </Box>
        <Box py={2}>
          <TextField
            fullWidth
            label="Quest Title"
            value={nameValue}
            onChange={handleSetName}
            required
            variant="outlined"
            error={!nameValuIsValid}
            helperText={nameError}
          />
        </Box>
        <Box py={2} display="flex" alignItems="center">
          <FormControl sx={{ mr: 1, width: 300 }}>
            <InputLabel id="multiple-checkbox-label">Choose Topics</InputLabel>
            <Select
              labelId="multiple-checkbox-label"
              multiple
              value={selectedSpecializationsId}
              onChange={handleSpecializationChange}
              input={<OutlinedInput label="Topic" />}
              renderValue={selectedIds => getNameOfSelectedEntity(selectedIds, specializations)}
            >
              {specializations.map(specialization => (
                <MenuItem key={specialization.id} value={specialization.id}>
                  <Checkbox
                    checked={Boolean(
                      selectedSpecializationsId?.find(specializationId => specializationId === specialization.id),
                    )}
                  />
                  <ListItemText primary={specialization.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: 300 }}>
            <InputLabel id="select-label">Access Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={accessType}
              label="Age"
              onChange={({ target }) => {
                setAccessType(target.value as ACCESS_TYPES)
              }}
              required
              variant="outlined"
              error={!accessType}
            >
              {accessItems}
            </Select>
          </FormControl>
        </Box>
        <Box py={2}>
          <FormLabel component="legend">Skillset</FormLabel>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              disableCloseOnSelect
              onChange={handleChange}
              id="combo-box-demo"
              options={skills}
              getOptionLabel={option => option?.name ?? ''}
              value={selectedSkills}
              renderInput={params => <TextField {...params} label="skillset" />}
            />
          </FormControl>
        </Box>
        <Box py={2}>
          <TextField
            fullWidth
            label="Quest UUID"
            value={uuidValue}
            disabled
            required
            variant="outlined"
            error={!uuidValuIsValid}
          />
        </Box>
        <Box mx={-1} display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box mb={2} mx={1} minWidth={320} flex={1}>
            <TextField
              fullWidth
              label="Price, usd"
              value={price}
              required={!isFree}
              error={isPriceInvalid}
              onChange={setPrice}
              disabled={priceDisabled}
              helperText={priceError}
              variant="outlined"
            />
          </Box>
          <Box mb={2} mx={1} minWidth={320} flex={1}>
            <TextField
              fullWidth
              label="Discounted Price, usd"
              value={discountPrice}
              error={isDiscountPriceInvalid}
              onChange={setDiscountPrice}
              disabled={discountPriceDisabled}
              helperText={discountPriceError}
              variant="outlined"
            />
          </Box>
          <Box mb={2} mx={1} minWidth={320} flex={1}>
            <TextField
              value={slug}
              onChange={handleSetSlug}
              // Если курс создается впервые, то проверять уникальность slug. При редактировании - не проверять
              error={!slugIsValid || !slugIsUnique}
              required
              fullWidth
              label="Quest Platform Link"
              placeholder="Example: it-reqruitment-course"
              helperText={slugError || (!slugIsUnique && 'slug is already taken')}
              variant="outlined"
            />
          </Box>
        </Box>

        <Box>
          <FormLabel component="legend">Payment Plan</FormLabel>
          {isPartialPaymentEnabled ? (
            <Box mx={-1} display="flex" justifyContent="space-between" flexWrap="wrap">
              <Box mb={2} mx={1} minWidth={320} flex={1}>
                <TextField
                  fullWidth
                  label="Количество платежей"
                  value={paymentCount}
                  onChange={setPaymentCount}
                  error={!paymentCountIsValid}
                  variant="outlined"
                  helperText={paymentCountError}
                  required
                />
              </Box>
              <Box mb={2} mx={1} minWidth={320} flex={1}>
                <TextField
                  fullWidth
                  label="Цена за один платеж, usd"
                  value={pricePerPayment}
                  onChange={setPricePerPayment}
                  error={!pricePerPaymentIsValid}
                  variant="outlined"
                  helperText={pricePerPaymentError}
                  required
                />
              </Box>
              <Box mb={2} mx={1} minWidth={320} flex={1}>
                <TextField
                  fullWidth
                  label="Сколько модулей открывается за один платеж"
                  value={modulesPerPayment}
                  onChange={setModulesPerPayment}
                  error={!modulesPerPaymentIsValid}
                  variant="outlined"
                  helperText={modulesPerPaymentError}
                  required
                />
              </Box>
            </Box>
          ) : (
            <Box>
              {currentPartialPaymentConfig && (
                <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                  <p>Платежи разделены на: {currentPartialPaymentConfig?.paymentCount} частей</p>
                  <p>
                    Каждая часть стоит:{' '}
                    {currentPartialPaymentConfig?.pricePerPayment?.amountCents
                      ? `${+currentPartialPaymentConfig?.pricePerPayment?.amountCents / 100} USD`
                      : 'Ошибка'}
                  </p>
                  <p>
                    Один платеж открывает доступ сразу к: {currentPartialPaymentConfig?.modulesPerPayment} модулям
                    (главам){' '}
                  </p>
                </Box>
              )}
            </Box>
          )}
          {partialPaymentProps && <PartialPaymentsList {...partialPaymentProps} />}
          <ButtonsGroup buttons={partialButtons} />
        </Box>

        <Box py={2} mx={-1} display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box mb={2} mx={1} minWidth={320} flex={1}>
            <Box component="label" display="flex" justifyContent="flex-start" alignItems="flex-start">
              <Switch checked={isReady} onChange={handleSetReady} color="primary" name="isReady" />
              <Box ml={2} mt={1}>
                <Typography>Quest is ready and available</Typography>
              </Box>
            </Box>
            <AuthorMetaField authorValue={authorValue} handleSetAuthor={handleSetAuthor} />
          </Box>
          <Box mb={2} mx={1} minWidth={320} flex={1}>
            <Box py={2}>
              <TextField
                fullWidth
                label="Quest Version"
                value={course?.version || '0.0.1'}
                disabled
                variant="outlined"
              />
            </Box>
          </Box>
        </Box>
        <Box py={2}>
          <TextField
            minRows={4}
            fullWidth
            maxRows={8}
            value={descriptionValue}
            onChange={handleSetDescription}
            label="Description"
            multiline
            variant="outlined"
          />
        </Box>
        <Box py={2}>
          <FormControl fullWidth>
            <InputLabel id="difficulty-select-label">Level</InputLabel>
            <Select
              labelId="difficulty-select-label"
              value={difficulty}
              onChange={({ target }) => setDifficulty(target.value)}
              required
              variant="outlined"
            >
              {difficultyItems}
            </Select>
          </FormControl>
        </Box>
        <Box py={2}>
          <FormLabel component="legend">Sponsors</FormLabel>
          <TextField
            fullWidth
            label="Add Sponsor"
            onKeyPress={handleAddSponsor}
            value={sponsorValue}
            onChange={({ target }) => setSponsorValue(target.value)}
            variant="outlined"
            helperText="Press Enter to add sponsor"
          />
          <Box display="flex" flexWrap="wrap" mt={1}>
            {sponsors.map(sponsor => (
              <Chip
                key={sponsor}
                label={sponsor}
                onDelete={handleDeleteSponsor(sponsor)}
                color="primary"
                style={{ margin: '4px' }}
              />
            ))}
          </Box>
        </Box>
        <ButtonsGroup buttons={buttons} />
      </form>
    </DefaultLayout>
  )
}

const getNameOfSelectedEntity = (selectedIds: string[], entities: (SpecializationType | SkillType)[]) => {
  const selectedEntity = entities.filter(entity => selectedIds.includes(entity.id ?? '')).map(entity => entity.name)

  return selectedEntity.join(', ')
}

const CourseImage = styled(Box)`
  border-radius: 24px;
  overflow: hidden;
  max-height: 375px;
  width: 100%;
`
