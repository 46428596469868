import React, { useCallback, useState } from 'react'
import { CourseDroplet, CourseDropletType } from '~/domain/course-droplet'
import { useCourseRoute } from '~/hooks/use-course-route'
import { DropletModal } from '~/modals/course-droplet-modal'
import { courseDropletsStore } from '~/store'
import { Course } from '~/types/course'

import { Button } from '@mui/material'

export const AddNewDroplet = ({ course }: { course: Course }) => {
  const { id: courseId } = useCourseRoute()

  const [modalIsVisible, setModalIsVisible] = useState(false)

  const handleUpdate = useCallback(
    (updatedDroplet: CourseDropletType) => {
      const droplet = new CourseDroplet({ ...updatedDroplet, courseId: courseId ?? '' })

      courseDropletsStore.createDroplet(droplet)
    },
    [courseId],
  )

  const handleOpenUserMetaModal = () => {
    setModalIsVisible(true)
  }

  const handleCloseUserMetaModal = () => {
    setModalIsVisible(false)
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpenUserMetaModal}>
        Добавить дроплет
      </Button>
      {modalIsVisible && (
        <DropletModal
          course={course}
          onSave={handleUpdate}
          onClose={handleCloseUserMetaModal}
          isOpen={modalIsVisible}
        />
      )}
    </div>
  )
}
