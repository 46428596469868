import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { GithubApi } from '~/api/github'
import { ButtonsGroup } from '~/components/buttons-group'
import { InputSelect } from '~/components/input-select'
import { TaskType, Structure, languages, Language } from '~/domain/task'
import { difficulty as difficultyOptions, Difficulty } from '~/domain/types'
import { getUniqueId } from '~/helpers/id'
import { containValueValidator } from '~/helpers/validation'
import { useInput } from '~/hooks/use-input'
import { NativeMdEditorWithoutMessage } from '~/modals/message-modal/message-modal-types/native-md'
import { Course } from '~/types/course'

import { Box, Dialog, TextField, FormGroup, Button } from '@mui/material'

interface TaskModalProps {
  onClose: () => void
  isOpen: boolean
  task?: TaskType
  onSave: (task: TaskType) => void
  course?: Course
}

export const TaskModal: FunctionComponent<TaskModalProps> = React.memo(props => {
  const { onClose, isOpen, task, onSave } = props
  const [codeExample, setCodeExample] = useState<Structure[]>(task?.codeExampleAnswer ?? [])
  const [structure, setStructure] = useState<Structure[]>(task?.structure ?? [])
  const id = task?.id ?? getUniqueId()
  const isEdit = Boolean(task)

  const {
    value: title,
    handleSetValue: handleSetTitle,
    isValid: titleIsValid,
  } = useInput({
    validators: containValueValidator,
    defaultValue: task?.title ?? '',
  })

  const { value: description, handleSetValue: handleSetDescription } = useInput({
    defaultValue: task?.description,
  })

  const { value: hint, handleSetValue: handleSetHint } = useInput({
    defaultValue: task?.hint ?? '',
  })

  const { value: language, handleSetValue: handleSetLanguage } = useInput({
    defaultValue: task?.language ?? Language.JAVASCRIPT,
    validators: containValueValidator,
  })

  const { value: difficulty, handleSetValue: handleSetDifficulty } = useInput({
    defaultValue: task?.difficulty ?? Difficulty.EASY,
    validators: containValueValidator,
  })

  const { value: codeExampleLink, handleSetValue: handleSetCodeExampleLink } = useInput({
    defaultValue: '',
  })

  const { value: structureLink, handleSetValue: handleSetStructureLink } = useInput({
    defaultValue: '',
  })

  const isSaveEnabled = useMemo(
    () => titleIsValid && description && difficulty && structure.length,
    [titleIsValid, description, difficulty, structure],
  )

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!isSaveEnabled) return

      const newTask: TaskType = {
        id,
        title,
        description,
        difficulty,
        hint,
        language,
        codeExampleAnswer: codeExample,
        structure,
        createdAt: task?.createdAt,
      }

      onSave(newTask)

      return onClose()
    },
    [
      codeExample,
      description,
      difficulty,
      hint,
      id,
      isSaveEnabled,
      language,
      onClose,
      onSave,
      structure,
      task?.createdAt,
      title,
    ],
  )

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel, back',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: onClose,
      },
      {
        text: isEdit ? 'Update changes' : 'Create task',
        variant: 'contained' as const,
        color: 'blue' as const,
        disabled: !isSaveEnabled,
        onClick: handleSubmit,
      },
    ],
    [onClose, isEdit, isSaveEnabled, handleSubmit],
  )

  const handleCodeExampleFromGithub = async () => {
    const response = await GithubApi.getDataByLink(codeExampleLink)

    if (!response) return

    if (Array.isArray(response)) {
      setCodeExample(response)
    } else {
      setCodeExample([response])
    }
  }

  const handleStructureFromGithub = async () => {
    const response = await GithubApi.getDataByLink(structureLink)

    if (!response) return

    if (Array.isArray(response)) {
      setStructure(response)
    } else {
      setStructure([response])
    }
  }

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" fullWidth open={isOpen}>
      <Box p={2}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box py={2}>
            <TextField
              fullWidth
              label="Task name will be used for users"
              required
              value={title}
              onChange={handleSetTitle}
              error={!titleIsValid}
              variant="outlined"
            />
          </Box>
          <Box py={1}>
            <InputSelect
              onChange={handleSetDifficulty}
              options={difficultyOptions}
              label="Level of difficulty"
              value={difficulty}
            />
          </Box>
          <Box py={2}>
            <label>Text for description section</label>
            <NativeMdEditorWithoutMessage markdownState={description} setMarkdownState={handleSetDescription} />
          </Box>
          <Box py={2}>
            <label>Text for hint</label>
            <NativeMdEditorWithoutMessage markdownState={hint} setMarkdownState={handleSetHint} />
          </Box>
          <Box py={2}>
            <InputSelect label="Language" value={language} options={languages} onChange={handleSetLanguage} />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              disabled
              label="Files loaded for readymade/help answer:"
              required
              value={codeExample.map(structure => structure.name).join(', ')}
              variant="outlined"
            />
          </Box>
          <Box py={2} justifyContent="space-between">
            <FormGroup row>
              <TextField
                label="Github repo link. Same structure, but for show answer feature"
                value={codeExampleLink}
                onChange={handleSetCodeExampleLink}
                variant="outlined"
                sx={{ flexGrow: 2 }}
              />
              <Button variant="contained" onClick={handleCodeExampleFromGithub}>
                Load content(show answer)
              </Button>
            </FormGroup>
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              disabled
              label="Files loaded: initial task content"
              required
              value={structure.map(structure => structure.name).join(', ')}
              variant="outlined"
            />
          </Box>
          <Box py={2} justifyContent="space-between">
            <FormGroup row>
              <TextField
                label="Github repo link. Structure /tests/* and /code/* init content"
                value={structureLink}
                onChange={handleSetStructureLink}
                variant="outlined"
                sx={{ flexGrow: 2 }}
              />
              <Button variant="contained" onClick={handleStructureFromGithub}>
                Load content(task)
              </Button>
            </FormGroup>
          </Box>
          <ButtonsGroup buttons={buttons} my={0} />
        </form>
      </Box>
    </Dialog>
  )
})
