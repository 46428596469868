import React, { useMemo, useEffect, useState } from 'react'
import { TaskApi } from '~/api/firebase/task'
import { TaskType, Language } from '~/domain/task'
import { DefaultInputFieldProps } from '~/hooks/use-input'
import { IMessage, IDE_TYPES } from '~/types/course'

import {
  Box,
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material'

type TaskProps = {
  message?: IMessage
  selectedTaskId: string
  setSelectedTaskId: (id: string) => void
  previouslyTaskId: string
  setPreviouslyTaskId: (id: string) => void
  additionalCheckMessage: string
  setAdditionalCheckMessage: (message: string) => void
  additionalCheckCode: string
  setAdditionalCheckCode: (code: string) => void
  ideType: IDE_TYPES
  setIdeType: (ideType: IDE_TYPES) => void
  points: DefaultInputFieldProps
}

const ideTypeLabel = {
  [IDE_TYPES.LITTLE]: 'Javascript',
  [IDE_TYPES.LARGE]: 'Solidity',
}

export const Task = ({
  message,
  selectedTaskId,
  setSelectedTaskId,
  previouslyTaskId,
  setPreviouslyTaskId,
  additionalCheckMessage,
  setAdditionalCheckMessage,
  additionalCheckCode,
  setAdditionalCheckCode,
  ideType,
  setIdeType,
  points,
}: TaskProps) => {
  const [tasks, setTasks] = useState<TaskType[]>([])
  const [selectedTask, setSelectedTask] = useState<TaskType | null>(null)
  const [previouslySelectedTask, setPreviouslyTask] = useState<TaskType | null>(null)
  const [needAdditionalChecks, setNeedAdditionalChecks] = useState(false)

  useEffect(() => {
    if (!message) return

    if (message.type === 'TASK') {
      message.taskId && setSelectedTaskId(message.taskId)
      message?.progressFromTaskId && setPreviouslyTaskId(message?.progressFromTaskId)
      message?.additionalCheckMessage && setAdditionalCheckMessage(message?.additionalCheckMessage)
      message?.additionalCheckCode && setAdditionalCheckCode(message?.additionalCheckCode)
      setIdeType(message?.ideType ?? IDE_TYPES.LARGE)
    }
  }, [message, setSelectedTaskId, setPreviouslyTaskId, setIdeType, setAdditionalCheckMessage, setAdditionalCheckCode])

  useEffect(() => {
    const getAllTasks = async () => {
      const response = await TaskApi.getAllTasks()
      setTasks(response)
    }

    getAllTasks()
  }, [])

  useEffect(() => {
    const userSelectedTask = tasks.find(task => task.id === selectedTaskId) ?? null
    const previouslySelectedTask = tasks.find(task => task.id === previouslyTaskId) ?? null

    setSelectedTask(userSelectedTask)
    setPreviouslyTask(previouslySelectedTask)
  }, [selectedTaskId, previouslyTaskId, tasks])

  const handleChange = (_event: React.SyntheticEvent, newValue: TaskType | null) => {
    setSelectedTask(newValue)
    setSelectedTaskId(newValue?.id ?? '')
    const language = newValue?.language

    switch (language) {
      case Language.SOLIDITY:
        setIdeType(IDE_TYPES.LARGE)
        break
      case Language.JAVASCRIPT:
      default:
        setIdeType(IDE_TYPES.LITTLE)
    }
  }

  const handleChangePreviously = (_event: React.SyntheticEvent, newValue: TaskType | null) => {
    setPreviouslyTask(newValue)
    setPreviouslyTaskId(newValue?.id ?? '')
  }

  const ideTypeOptions = useMemo(
    () => Object.values(IDE_TYPES).map(value => ({ label: ideTypeLabel[value], value })),
    [],
  )

  const tasksForPreviously = useMemo(() => tasks.filter(task => task.id !== selectedTask?.id), [tasks, selectedTask])

  const handleIdeTypeChange = (e: { target: { value: any } }) => {
    const value = e.target.value
    setIdeType(value)
  }

  const hasError = useMemo(
    () => (previouslySelectedTask ? selectedTask?.id === previouslySelectedTask.id : false),
    [selectedTask, previouslySelectedTask],
  )

  return (
    <Box margin="16px 0">
      <Box py={2}>
        <TextField
          fullWidth
          label="Поинты за выполнение задачи"
          type="number"
          value={points?.value}
          onChange={e => points?.handleSetValue?.(e.target.value)}
          variant="outlined"
          inputProps={{
            min: 0,
            max: 99999,
          }}
        />
      </Box>
      <Box py={2}>
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            onChange={handleChange}
            id="combo-box-demo"
            options={tasks}
            getOptionLabel={option => option?.title ?? ''}
            value={selectedTask}
            renderInput={params => (
              <>
                <TextField error={hasError} {...params} label="Задача для выполнения" />
                {hasError && (
                  <Typography color="red">
                    Целевая задача и задача из которой нужно наследовать код, не могут быть равны
                  </Typography>
                )}
              </>
            )}
          />
        </FormControl>
      </Box>
      <Box py={2}>
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            onChange={handleChangePreviously}
            id="combo-box-demo"
            options={tasksForPreviously}
            getOptionLabel={option => option?.title ?? ''}
            value={previouslySelectedTask}
            renderInput={params => <TextField {...params} label="Наследовать код пользователя из задачи" />}
          />
        </FormControl>
      </Box>
      <Box py={2}>
        <FormControlLabel
          control={
            <Checkbox checked={needAdditionalChecks} onChange={e => setNeedAdditionalChecks(e.target.checked)} />
          }
          label="Need additional checks?"
        />
      </Box>
      {needAdditionalChecks && (
        <>
          <Box py={2}>
            <TextField
              fullWidth
              label="Additional check messageId"
              value={additionalCheckMessage}
              onChange={e => setAdditionalCheckMessage(e.target.value)}
              variant="outlined"
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Additional check code"
              value={additionalCheckCode}
              onChange={e => setAdditionalCheckCode(e.target.value)}
              variant="outlined"
              multiline
              minRows={3}
              maxRows={Infinity}
            />
          </Box>
        </>
      )}

      <Box my={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>IDE type</InputLabel>
          <Select value={ideType} onChange={handleIdeTypeChange} label="IDE type">
            {ideTypeOptions.map(({ label, value }, index) => (
              <MenuItem key={`${value}-${index}`} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}
