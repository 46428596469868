import React, { useMemo, useEffect } from 'react'
import { IMessage, MessagesWithAuthor, MESSAGES_TYPES } from '~/types/course'

import { FormControl, InputLabel, MenuItem, Select, Box } from '@mui/material'

type Props = {
  type: MESSAGES_TYPES
  value: string | any
  label?: string
  onChange: (value: any) => void
  options: {
    text?: string
    value: string | any
  }[]
  onOpen?: (value: any) => void
  message?: IMessage
  setAuthorId: (value: any) => void
}

export const AuthorIdSelect = ({ type, value, label, options, onChange, onOpen, message, setAuthorId }: Props) => {
  const defaultOptionLabel = useMemo(() => {
    const isMessageDialog = type === MESSAGES_TYPES.DIALOG || type === undefined

    return isMessageDialog ? 'Студент' : 'None'
  }, [type])

  useEffect(() => {
    if (!message) return
    if (MessagesWithAuthor.includes(message.type)) {
      setAuthorId(message?.authorId)
    }
  }, [message, setAuthorId])

  return (
    <Box my={2}>
      <FormControl fullWidth variant="outlined">
        <InputLabel>{label}</InputLabel>
        <Select defaultValue="none" value={value} onChange={onChange} onOpen={onOpen} label={label}>
          <MenuItem value="none">{defaultOptionLabel}</MenuItem>
          {options.map(({ text, value }, index) => (
            <MenuItem key={`${value}-${index}`} value={value}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
