import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { CommonObjectType } from '~/helpers/mdParser/types'
import { IMessage } from '~/types/course'

import { Box, TextField } from '@mui/material'

import { CommentFields, CommentFieldsProps } from '../fields/comment-fields'
import { NativeMdEditorWithoutMessage } from '../native-md'
import { fillFieldsOnUpload, makeFillGapOptionsFromRaw } from './helpers'

export type FillTheGapProps = {
  message?: IMessage
  title?: string
  description?: string
  content: string
  setContent: React.Dispatch<React.SetStateAction<string>>
  setDescription: React.Dispatch<React.SetStateAction<string>>
  setTitle: (str: string) => void
  setOptions: React.Dispatch<React.SetStateAction<CommonObjectType>>
} & CommentFieldsProps

export const FillTheGap = ({
  message,
  successAnswer,
  wrongAnswer,
  title,
  setTitle,
  description,
  setDescription,
  content,
  setContent,
  setOptions,
}: FillTheGapProps) => {
  const [rawFillGapOptions, setRawFillGapOptions] = React.useState<string>('')
  const [optionsError, setOptionsError] = React.useState(false)

  useEffect(() => {
    fillFieldsOnUpload({
      message,
      setTitle,
      setDescription,
      setRawFillGapOptions,
      setContent,
      wrongAnswer,
      successAnswer,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  React.useEffect(() => {
    if (rawFillGapOptions) {
      const extractedOptions: any = makeFillGapOptionsFromRaw(rawFillGapOptions, setOptionsError)

      setOptions(extractedOptions)
    }
  }, [rawFillGapOptions, setOptions])

  return (
    <Box>
      <Box py={2}>
        <TextField
          fullWidth
          label="Заголовок"
          value={title}
          onChange={e => setTitle(e.target.value)}
          variant="outlined"
          style={{ marginTop: 10 }}
        />

        <TextField
          fullWidth
          label="Описание"
          value={description}
          onChange={e => setDescription(e.target.value)}
          variant="outlined"
          style={{ marginTop: 10 }}
        />

        <Hint>
          <h3>
            <strong>Код:</strong>
          </h3>
          <p>
            <strong>id_варианта</strong> вы придумываете сами
          </p>
          <p>Пример заполнения:</p>
          <pre>
            <p>```bash</p>
            <p>
              Hello, my name &lt;answerId&gt;<strong>id_варианта1</strong>&lt;/answerId&gt; Nickel.
            </p>
            <p>
              Apple is a &lt;answerId&gt;<strong>id_варианта2</strong>&lt;/answerId&gt; in Russian.
            </p>
            <p>```</p>
          </pre>
        </Hint>

        <NativeMdEditorWithoutMessage markdownState={content} setMarkdownState={setContent} />

        <Hint>
          <h3>
            <strong>Привязка ответов</strong>
          </h3>
          <p>форма ответа:</p>
          <p>
            значение_варианта: <strong>id_варианта;</strong>
          </p>
          <p>
            используйте разделительный символ <strong>&apos;;&apos;</strong>
          </p>
          <p>Пример заполнения:</p>
          <pre>
            <p>is=1;</p>
            <p>are=2;</p>
            <p>was=3;</p>
            <p>яблоко=4;</p>
            <p>апельсин=5;</p>
          </pre>
        </Hint>
        {optionsError && <p style={{ color: 'red' }}>Options are incorrect. Follow example please</p>}
        <FillTheGapOptionsTextarea
          style={{ marginTop: '15px' }}
          value={rawFillGapOptions}
          onChange={e => setRawFillGapOptions(e.target.value)}
        />

        <CommentFields wrongAnswer={wrongAnswer} successAnswer={successAnswer} />
      </Box>
    </Box>
  )
}

const FillTheGapOptionsTextarea = styled('textarea')`
  width: 100%;
  min-height: 200px;
`

const Hint = styled('div')`
  background-color: aliceblue;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.65);
  border-radius: 5px;
  margin: 10px 0;

  pre {
    margin-top: 5px;
    border: 1px solid rgba(0, 0, 0, 0.65);
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.65);
    padding: 10px;
    font-family: monospace;
  }
`
