import React from 'react'
import { AchievementsApi, AchievementSeriesType, AchievementType } from '~/api/achievements'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  DialogContent,
  Button,
  Dialog,
  TextField,
} from '@mui/material'

import { Achievement } from './previews/achievement'
import { ProfileAchievement } from './previews/profile-achievement'

type Props = {
  data: AchievementSeriesType[]
}

const TableAchievements = ({ data }: Props) => {
  const [selectedAchievement, setSelectedAchievement] = React.useState<AchievementType | null>(null)
  const [previewOpen, setPreviewOpen] = React.useState(false)
  const [editOpen, setEditOpen] = React.useState(false)
  const [idToEdit, setIdToEdit] = React.useState<string | null>(null)

  const handlePreview = async (id: string) => {
    const achievement = await AchievementsApi.getAchievementById(id)
    setSelectedAchievement(achievement)
    setPreviewOpen(true)
  }

  const handleEdit = async (id: string, seriesName: string) => {
    setIdToEdit(id)
    const achievement = await AchievementsApi.getAchievementById(id)
    if (achievement) {
      setSelectedAchievement(achievement)
    } else {
      // Create a new empty achievement if none exists
      const newAchievement: AchievementType = {
        series: {
          name: seriesName,
        },
        title: '',
        description: '',
        image: '',
        imageAlt: '',
      }
      setSelectedAchievement(newAchievement)
    }
    setEditOpen(true)
  }

  const handleSave = async (achievement: AchievementType) => {
    if (idToEdit) {
      await AchievementsApi.updateAchievementById(idToEdit, achievement)
    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>User count</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(achievementSeries => (
              <TableRow key={achievementSeries.id}>
                <TableCell>{achievementSeries.id}</TableCell>
                <TableCell>{achievementSeries.name}</TableCell>
                <TableCell>{achievementSeries.users.length}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => achievementSeries.id && handlePreview(achievementSeries.id)}
                  >
                    Preview
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => achievementSeries.id && handleEdit(achievementSeries.id, achievementSeries.name)}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)} maxWidth="lg">
        <DialogContent>
          {selectedAchievement && (
            <Box sx={{ display: 'flex', gap: 4, p: 2 }}>
              <Achievement
                title={selectedAchievement.title}
                img={selectedAchievement.image}
                alt={selectedAchievement.imageAlt}
                description={selectedAchievement.description}
                id={'0'}
              />
              <Box sx={{ height: '20%' }}>
                <ProfileAchievement
                  title={selectedAchievement.title}
                  img={selectedAchievement.image}
                  alt={selectedAchievement.imageAlt}
                />
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={editOpen} onClose={() => setEditOpen(false)} maxWidth="lg">
        <DialogContent>
          {selectedAchievement && (
            <Box>
              <Box sx={{ p: 2 }}>
                <TextField
                  label="Title"
                  value={selectedAchievement.title}
                  onChange={e => setSelectedAchievement({ ...selectedAchievement, title: e.target.value })}
                />
                <TextField
                  label="Description"
                  value={selectedAchievement.description}
                  onChange={e => setSelectedAchievement({ ...selectedAchievement, description: e.target.value })}
                />
                <TextField
                  label="Image"
                  value={selectedAchievement.image}
                  onChange={e => setSelectedAchievement({ ...selectedAchievement, image: e.target.value })}
                />
                <TextField
                  label="Image Alt"
                  value={selectedAchievement.imageAlt}
                  onChange={e => setSelectedAchievement({ ...selectedAchievement, imageAlt: e.target.value })}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (selectedAchievement) {
                      handleSave(selectedAchievement)
                      setEditOpen(false)
                    }
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default TableAchievements
