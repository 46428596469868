import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { ButtonsGroup } from '~/components/buttons-group'
import { InputSelect } from '~/components/input-select'
import { LinkPaymentStatus, PaymentLinksType } from '~/domain/payment-links'
import { getUniqueId } from '~/helpers/id'
import { containValueValidator } from '~/helpers/validation'
import { useCourseRoute } from '~/hooks/use-course-route'
import { useInput } from '~/hooks/use-input'
import { Course } from '~/types/course'

import { Box, Dialog, Switch, TextField, Typography } from '@mui/material'

interface Props {
  onClose: () => void
  isOpen: boolean
  link?: PaymentLinksType
  onSave: (link: PaymentLinksType) => void
  course?: Course
}

export const PaymentLinksModal: FunctionComponent<Props> = React.memo(props => {
  const { onClose, isOpen, course, link, onSave } = props
  const id = link?.id ?? getUniqueId()
  const isEdit = Boolean(link)
  const { id: courseId } = useCourseRoute()

  const chaptersOption = useMemo(
    () =>
      course?.chapters?.map((chapter, index) => ({
        label: `${chapter?.name} #${index + 1}`,
        value: chapter.id,
      })),
    [course?.chapters],
  )

  const { value: chapterId, handleSetValue: handleSetCapterId } = useInput({
    defaultValue: link?.chapterId || '',
  })

  const {
    value: price,
    handleSetValue: setPrice,
    disabled: priceDisabled,
    isValid: priceIsValid,
    error: priceError,
  } = useInput({
    defaultValue: link?.price.amountCents ? `${link?.price.amountCents / 100}` : '',
    validators: containValueValidator,
  })

  const { checked: isFullCourse, handleSetChecked } = useInput({
    defaultChecked: !link?.chapterId,
  })

  const handleUpdateChecked = (_: React.ChangeEvent<HTMLInputElement>, state: boolean) => {
    handleSetChecked(state)

    if (state) {
      handleSetCapterId(null)
    }
  }

  const isSaveEnabled = useMemo(() => priceIsValid, [priceIsValid])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!isSaveEnabled) return

      const newData: PaymentLinksType = {
        id,
        courseId: courseId ?? '',
        createdAt: link?.createdAt,
        price: {
          amountCents: price * 100,
          currencyCode: 'USD',
        },
        chapterId,
        status: link?.status || LinkPaymentStatus.ACTIVE,
      }

      onSave(newData)

      return onClose()
    },
    [chapterId, courseId, price, id, isSaveEnabled, link?.createdAt, link?.status, onClose, onSave],
  )

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel, Back',
        type: 'button' as const,
        variant: 'contained' as const,
        color: 'white' as const,
        onClick: onClose,
      },
      {
        text: isEdit ? 'Save' : 'Create Link',
        variant: 'contained' as const,
        color: 'blue' as const,
        disabled: !isSaveEnabled,
        onClick: handleSubmit,
      },
    ],
    [onClose, isEdit, isSaveEnabled, handleSubmit],
  )

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" fullWidth open={isOpen}>
      <Box p={2}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Цена, usd"
            value={price}
            error={!priceIsValid}
            onChange={setPrice}
            disabled={priceDisabled}
            helperText={priceError}
            variant="outlined"
          />
          <Box py={1}>
            <Box component="label" display="flex" justifyContent="flex-start" alignItems="flex-start">
              <Switch checked={isFullCourse} onChange={handleUpdateChecked} color="primary" name="isFree" />
              <Box ml={2} mt={1}>
                <Typography>Оплата за полный курс</Typography>
              </Box>
            </Box>
          </Box>
          {!isFullCourse && (
            <Box py={1}>
              <InputSelect
                onChange={handleSetCapterId}
                options={chaptersOption || []}
                label="Глава"
                value={chapterId}
              />
            </Box>
          )}

          <ButtonsGroup buttons={buttons} my={0} />
        </form>
      </Box>
    </Dialog>
  )
})
